import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UxService } from '~/app/core/services';
import { IApi } from '~/app/open-age/core/services';

@Component({
  selector: 'shared-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  @Input()
  name: string;

  @Input()
  samples: Array<{ name: string, mapper?: string, url_xlsx: string }>;

  @Input()
  mappers: Array<{ name: string, value: string }>;

  @Input()
  selectedMapper = { format: 'default' };

  @Input()
  uploader: IApi<any>;

  errorMessage: string;

  file: File;
  isDisabled = false;

  constructor(
  ) { }

  ngOnInit() {
  }

  onSelect($event) {
    const files = $event.srcElement.files;
    this.file = files && files.length ? files[0] : null;
  }

  upload() {
    this.isDisabled = true;

    const subject = new Subject<string>();
    this.uploader.upload(this.file, 'bulk', this.selectedMapper).subscribe((message) => {
      this.isDisabled = false;
      subject.next(message);
    }, (err) => {
      this.errorMessage = err;
      this.isDisabled = false;
      subject.error(err);
    });

    return subject.asObservable();
  }

}
