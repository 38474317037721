import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatListModule, MatTableModule } from '@angular/material';
import { InsightModule } from '../../open-age/insight/insight.module';
import { SharedModule } from '../../shared/shared.module';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { ActivitySummaryComponent } from './activity-summary/activity-summary.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { HeatMapMonthComponent } from './heatMap/heat-map-month/heat-map-month.component';
import { HeatMapWidgetComponent } from './heatMap/heat-map-widget/heat-map-widget.component';
import { JournalListComponent } from './journal-list/journal-list.component';
import { LogDetailsComponent } from './log-details/log-details.component';
import { LogListComponent } from './log-list/log-list.component';
import { ReportDataComponent } from './report-data/report-data.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportTypeListComponent } from './report-type-list/report-type-list.component';
import { BubbleWidgetComponent } from './widgets/bubble-widget/bubble-widget.component';
import { CalendarDateComponent } from './widgets/calendar-date/calendar-date.component';
import { CalendarWidgetComponent } from './widgets/calendar-widget/calendar-widget.component';
import { CalenderDayDetailComponent } from './widgets/calender-day-detail/calender-day-detail.component';
import { DualGraphWidgetComponent } from './widgets/dual-graph-widget/dual-graph-widget.component';
import { GoogleMapComponent } from './widgets/google-map-widget/google-map-widget.component';
import { GraphWidgetComponent } from './widgets/graph-widget/graph-widget.component';
import { GridFilterWidgetComponent } from './widgets/grid-filter-widget/grid-filter-widget.component';
import { GridWidgetComponent } from './widgets/grid-widget/grid-widget.component';
import { TableWidgetComponent } from './widgets/table-widget/table-widget.component';

const components = [
  ActivityListComponent,
  AnalyticsComponent,
  ActivitySummaryComponent,
  ReportTypeListComponent,
  ReportListComponent,
  ReportDataComponent,
  GridWidgetComponent,
  TableWidgetComponent,
  GraphWidgetComponent,
  DualGraphWidgetComponent,
  JournalListComponent,
  CalendarWidgetComponent,
  CalendarDateComponent,
  CalenderDayDetailComponent,
  HeatMapWidgetComponent,
  HeatMapMonthComponent,
  GoogleMapComponent,
  GridFilterWidgetComponent,
  BubbleWidgetComponent,
  LogListComponent,
  LogDetailsComponent
];
const thirdPartyModules = [
  MatCardModule,
  MatTableModule,
  MatGridListModule,
  MatDividerModule,
  MatListModule,
  MatExpansionModule,
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyA3-BQmJVYB6_soLJPv7cx2lFUMAuELlkM',
    libraries: ['places']
  }),
];
const services = [];
const guards = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    InsightModule,
    ...thirdPartyModules,
  ],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  providers: [...services, ...guards],
  entryComponents: [CalenderDayDetailComponent]
})
export class OaInsightModule { }
