import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UxService } from '~/app/core/services';
import { RoleService } from '../../core/services';
import { AppointmentType } from '../models/appointment-type.model';
import { WelcomeApi } from './welcome-api.base';

@Injectable()
export class AppointmentTypeService extends WelcomeApi<AppointmentType> {
  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('appointmentTypes', http, roleService, uxService);
  }
}
