import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DocsService } from '~/app/open-age/drive/services';
import { FileDetailsBaseComponent } from '~/app/open-age/drive/components/file-details-base.component';
import { UxService } from '~/app/core/services';
import { FileUploaderDialogComponent } from '../file-uploader-dialog/file-uploader-dialog.component';

@Component({
  selector: 'app-file-detail',
  templateUrl: './file-detail.component.html',
  styleUrls: ['./file-detail.component.css']
})
export class FileDetailComponent extends FileDetailsBaseComponent {

  isProcessing: boolean;

  constructor(
    public dialog: MatDialog,
    private service: DocsService,
    private uxService: UxService
  ) {
    super(service, uxService);
  }

  openDialog(): void {
    const instance = this.uxService.openDialog(FileUploaderDialogComponent)
    instance.componentInstance.entity = this.properties.entity;
    instance.componentInstance.folder = this.properties.folder;
    instance.componentInstance.doc = this.properties;
    instance.afterClosed().subscribe(() => {
      this.get(this.properties.id)
    });
  }
}
