import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Address } from '~/app/open-age/core/models';
import { WizStepBaseComponent } from '~/app/open-age/core/structures/wiz/wiz-step-base.component';
import { Student } from '~/app/open-age/directory/models';
import { StudentService } from '~/app/open-age/directory/services';

@Component({
  selector: 'directory-student-address-editor',
  templateUrl: './student-address-editor.component.html',
  styleUrls: ['./student-address-editor.component.css']
})
export class StudentAddressEditorComponent extends WizStepBaseComponent implements OnInit {

  @Input()
  usercode: string;

  @Input()
  address: Address = new Address();

  @Input()
  student: Student = new Student();

  @Input()
  readonly = false;

  @Input()
  required = true;

  constructor(private api: StudentService) {
    super();
  }

  ngOnInit() {
  }

  validate(): any {

    if (!this.address.line1) {
      return 'Line 1 is required';
    }

    if (!this.address.line2) {
      return 'Line 2 is required';
    }

    if (!this.address.city) {
      return 'City is required';
    }

    if (!this.address.state) {
      return 'State is Required';
    }
    if (!this.address.district) {
      return 'District is Required';
    }

    if (!this.address.pinCode) {
      return 'Pin Number is required';
    }

    return true;
  }
  complete(): Observable<any> | boolean {
    if (!this.student.type) {
      this.student.type = 'normal';
    }
    if (!this.student.status) {
      this.student.status = 'active';
    }
    // TODO: add defaults
    if (this.readonly) {
      return this.api.get(this.student.id);
    }
    if (this.student.id) {
      return this.api.update(this.student.id, this.student);
    } else {
      return this.api.create(this.student);
    }
  }
}
