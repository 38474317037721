import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { environment } from '~/environments/environment';
import { UxService } from '../../../core/services/ux.service';
import { Tenant } from '../../directory/models';
import { ServerData } from '../models';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor(
    private http: HttpClient,
    private auth: RoleService,
    private uxService: UxService
  ) {
  }

  public get(): Observable<Tenant> {
    const subject = new Subject<Tenant>();

    const tenant = this.auth.currentTenant();

    if (tenant && tenant.timeStamp && moment(tenant.timeStamp).add(environment.cacheDuration, 'm').isAfter(new Date())) {
      setTimeout(() => subject.next(tenant));
      return subject.asObservable();
    }

    let host = window.location.host;
    if (host === 'localhost:4205') { // Hack to handle debugging
      host = environment.host;
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    // if (timeStamp) {
    //   headers['If-Modified-Since'] = timeStamp.toISOString();
    // }

    if (environment.organization && environment.organization.code) {
      headers['x-organization-code'] = environment.organization.code;
    }

    this.http.get<ServerData<Tenant>>(`${environment.source}/${environment.code || 'host:' + host}`, {
      headers: new HttpHeaders(headers)
    }).subscribe((dataModel) => {
      try {
        const isSuccess = dataModel.isSuccess !== undefined ? dataModel.isSuccess : (dataModel as any).IsSuccess;
        if (isSuccess) {
          dataModel.data.timeStamp = new Date();
          // dataModel.data.services[0].url = 'http://127.0.0.1:3077/api'
          // dataModel.data.services[1].url = 'http://127.0.0.1:3035/api'
          this.auth.setTenant(dataModel.data);
          return subject.next(dataModel.data);
        }
        const errorCode = dataModel.error || dataModel.code || dataModel.message || 'failed';
        if (errorCode === 'RESOURCE_NOT_FOUND') {
          return subject.next(new Tenant({
            code: environment.code,
            host,
            level: 'new'
          }));
        } else {
          const error = new Error(errorCode);
          this.uxService.handleError(error);
          return subject.error(error);
        }
      } catch (err) {
        this.uxService.handleError(err.message || err);
        return subject.error(err);
      }
    }, (err) => {
      this.uxService.handleError(err.message || err);
      return subject.error(err);
    });
    return subject.asObservable();
  }
}
