import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { RoleService } from 'src/app/open-age/core/services';
import { Entity } from '../../core/models/entity.model';
import { IInputValidator } from '../../core/services/input-validator.interface';
import { Doc, Folder, Placeholder } from '../models';
import { DocsService } from '../services/docs.service';
import { PageOptions, Page } from '../../core/models';
import { IPager, IPage, PagerOptions } from '../../core/structures';
import { MatSlideToggleChange } from '@angular/material';

export class FileListBaseComponent implements IPage<Doc>, IPager, OnInit, OnChanges, OnDestroy {

  @Output()
  changed: EventEmitter<File> = new EventEmitter();

  @Output()
  count: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  entity: Entity;

  @Input()
  types: string[];

  /*
      limits files to one folder only
  */

  @Input()
  folder: Folder;

  /*
      Set of files to show with + icon. These are just placeholder for files
  */
  @Input()
  placeholders: boolean | Placeholder[]; // true will shows set of files configured for that entity type

  /*
      can files be added and removed
      default: false
  */
  @Input()
  readonly: boolean;

  @Input()
  view: 'list' | 'grid' | 'carousel' | 'horizontal' | 'recentFile' | 'folderList' | 'gallery' | 'galleryCertificate' = 'list';

  @Input()
  addNewFile = false;

  /*
      sort the docs by name, size, date
  */
  @Input()
  sort: string;

  @Input()
  isPublic: boolean;

  @Input()
  fileName: string;

  @Input()
  droppable?: boolean; // default true

  @Output()
  selected: EventEmitter<Doc> = new EventEmitter();
  @Output()
  created: EventEmitter<Doc> = new EventEmitter();
  @Output()
  removed: EventEmitter<Doc> = new EventEmitter();
  isProcessing: boolean;

  items: Doc[];
  selectedFile: Doc;

  currentPageNo = 1;
  totalPages = 0;
  pageNo = 1;
  pageSize: number;
  limit: number;
  offset: number;
  total: number;
  stats: any;
  desc: boolean;

  pager: IPager;
  i: number

  private options: PagerOptions<Doc>;

  removeSubscription: Subscription;
  uploadSubscription: Subscription;
  constructor(
    private auth: RoleService,
    private fileService: DocsService,
    private errorHandler: ErrorHandler,
    private validator: IInputValidator,
    pageOptions?: {
      limit: number,
      offset: number,
      sort: string,
      desc: boolean,
      noPaging: boolean,
      query: any,
      path: any
    } | PageOptions
  ) {
    this.options = new PagerOptions()
    this.options.pageOptions = pageOptions
    this.pager = this;
  }

  ngOnInit() {
    this.fetch(this.options.pageOptions);
    this.uploadSubscription = this.fileService.afterUpload.subscribe((doc: Doc) => {
      let match = true;
      if (this.entity && (doc.entity.type !== this.entity.type || doc.entity.id !== `${this.entity.id}`)) {
        match = false;
      }

      if (this.types && this.types.length) {
        match = false;
        if (this.types.find((i) => i === doc.type)) {
          match = true;
        }
      }

      if (match) {
        this.items.push(doc);
      }
    });

    this.removeSubscription = this.fileService.afterRemove.subscribe(() => {
      this.fetch();
    });
  }

  ngOnChanges() {
    if (this.folder) {
      this.entity = this.folder.entity;
      this.fetch();
    }
  }

  move(item: Doc, position: string, index: number) {
    top
    if (position == "bottom") {
      this.fileService.update(item.id, { orderNo: this.getMinimum() - 1 }).subscribe(doc => {
        item = doc
        this.fetch(this.options.pageOptions);
      })
    } else if (position == "top") {
      this.fileService.update(item.id, { orderNo: this.getMaximum() + 1 }).subscribe(doc => {
        item = doc
        this.fetch(this.options.pageOptions);
      })
    } else if (position == "up") {
      if (index > 0) {
        let orderNo = this.items[index - 1].orderNo;
        this.fileService.update(this.items[index - 1].id, { orderNo: item.orderNo }).subscribe(doc => {
          item = doc
          this.fetch(this.options.pageOptions);
        })
        this.fileService.update(item.id, { orderNo: orderNo }).subscribe(doc => {
          item = doc
          this.fetch(this.options.pageOptions);
        })
      }
    } else if (position == "down") {
      if (index < this.items.length - 2) {
        let orderNo = this.items[index + 1].orderNo;
        this.fileService.update(this.items[index + 1].id, { orderNo: item.orderNo }).subscribe(doc => {
          item = doc
          this.fetch(this.options.pageOptions);
        })
        this.fileService.update(item.id, { orderNo: orderNo }).subscribe(doc => {
          item = doc
          this.fetch(this.options.pageOptions);
        })
      }
    }
  }

  getMinimum(): number {
    let min = 1000
    for (const item of this.items) {
      if (item.orderNo && item.orderNo < min) {
        min = item.orderNo
      }
    }
    return min
  }

  getMaximum(): number {
    let max = -1000
    for (const item of this.items) {
      if (item.orderNo && item.orderNo > max) {
        max = item.orderNo
      }
    }
    return max
  }

  public toggle(item: Doc, event: MatSlideToggleChange) {
    this.isProcessing = true
    if (item.status == 'active') {
      item.status = 'inactive'
    } else {
      item.status = 'active'
    }
    this.fileService.update(item.id, item).subscribe(() => {
      this.isProcessing = false
      this.fetch(this.options.pageOptions);
    })
  }

  showPage(pageNo: number) {
    if (this.isProcessing) {
      return;
    }
    if (pageNo === -2) {
      pageNo = 1;
      return;
    }

    if (pageNo === -1) {
      pageNo = this.totalPages;
      return;
    }

    return this.fetch(this.convertToPageOption(pageNo));
  }

  private convertToPageOption(pageNo: number) {
    const options = new PageOptions();
    if (this.options.pageOptions) {
      options.offset = (pageNo - 1) * this.options.pageOptions.limit;
      options.limit = this.options.pageOptions.limit;
      options.sort = this.options.pageOptions.sort;
    }
    return options;
  }

  fetch(options?: PageOptions | {
    offset?: number,
    limit?: number,
    sort?: string,
    desc?: boolean,
    path?: string
  }) {
    const subject = new Subject<Page<Doc>>();
    this.isProcessing = true;
    this.fileService.searchByEntity(this.entity, this.folder, this.fileName, options, { isPublic: true }).subscribe((i) => {
      this.items = [];
      i.items = this.formatItems(i.items)
      if (this.types && this.types.length) {
        i.items.forEach((item) => {
          if (this.types.find((t) => t === item.type)) {
            return this.items.push(item);
          }
        });
      } else {
        this.items = i.items;
      }

      if (this.view === 'carousel') {
        this.getDefaultSelectedFile();
      }

      subject.next(i);
      this.stats = i.stats;
      this.items = i.items;
      this.total = i.total || this.items.length;
      this.currentPageNo = i.pageNo;
      this.pageSize = i.pageSize;
      if (options) {
        this.i = options.offset;
        this.limit = options.limit;
        this.offset = options.offset;
        this.sort = options.sort;
      }


      if (options && options.limit) {
        this.totalPages = Math.ceil(this.total / options.limit);
      } else {
        this.totalPages = 1;
      }

      this.count.emit(this.items.length);
      this.isProcessing = false;
    }, (error) => {
      this.errorHandler.handleError(error.message || error);
      this.isProcessing = false;
      subject.error(error);
    });
    return subject.asObservable();
  }

  formatItems(items: Doc[]) {
    let activeList: Doc[] = []
    let draftList: Doc[] = []
    for (const item of items) {
      if (item.status == "active") {
        activeList.push(item)
      } else {
        draftList.push(item)
      }
    }
    return activeList.concat(draftList)
  }

  remove(id) {
    this.isProcessing = true;

    this.fileService.remove(id).subscribe((data) => {
      this.isProcessing = false;
      this.items = this.items.filter((item) => item.id !== id);
    }, (err) => {
      this.isProcessing = false;
      this.errorHandler.handleError(err.message || err);
    });
  }

  getDefaultSelectedFile() {
    this.selectedFile = this.items[0];
  }
  next() {
    const index = this.items.findIndex((file) => file.id === this.selectedFile.id);

    if (index > -1) {
      if (index >= this.items.length - 1) {
        this.selectedFile = this.items[0];
      } else {
        this.selectedFile = this.items[index + 1];
      }
    }
  }

  previous() {
    const index = this.items.findIndex((file) => file.id === this.selectedFile.id);

    if (index > -1) {
      if (index === 0) {
        const lastIndex = this.items.length - 1;
        this.selectedFile = this.items[lastIndex];
      } else {
        this.selectedFile = this.items[index - 1];
      }
    }
  }

  onFileSelect(doc) {
    this.selected.emit(doc)
  }

  onDotClick(index) {
    this.selectedFile = this.items[index];
  }

  ngOnDestroy(): void {
    this.uploadSubscription.unsubscribe();
    this.removeSubscription.unsubscribe();
  }
}
