import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Course, Division } from '~/app/open-age/directory/models';
import { CourseService } from '~/app/open-age/directory/services';

@Component({
  selector: 'directory-course-picker',
  templateUrl: './course-picker.component.html',
  styleUrls: ['./course-picker.component.css']
})
export class CoursePickerComponent implements OnChanges {

  @Input()
  course: Course;

  @Input()
  readonly = false;

  @Input()
  required = false;

  @Input()
  division: Division;

  @Input()
  placeholder = 'Division';

  @Input()
  disabled = false;

  @Output()
  changed: EventEmitter<Course> = new EventEmitter();

  @ViewChild('userInput')
  userInput: ElementRef<HTMLInputElement>;

  @ViewChild('divisionPicker')
  matAutocomplete: MatAutocomplete;

  control = new FormControl();

  isEditing = false;

  items: Course[];

  constructor(
    private employeeService: CourseService
  ) {
  }

  ngOnChanges() {
    if (this.division && this.division.courses && this.division.courses.length) {
      this.items = this.division.courses;
    } else {
      this.control.valueChanges.pipe(
        debounceTime(300),
        switchMap((value) => this.employeeService.search({ name: value }))
      ).subscribe((page) => {
        if (this.division && this.division.courses && this.division.courses.length) {
          this.items = this.division.courses;
        } else {
          this.items = page.items;
        }
      });
    }
  }

  displayCourse(course?: Course): string | undefined {
    if (!course) {
      return '';
    }

    if (typeof course === 'string') {
      return course;
    }

    if (!course.name || !course.name) {
      return course.code;
    }

    return `${course.name}`;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.course = event.option.value;

    this.changed.emit(this.course);

    this.userInput.nativeElement.value = this.displayCourse(this.course);
    this.control.setValue(null);
  }
}
