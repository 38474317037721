import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { UxService } from '~/app/core/services';
import { ComponentListBaseComponent } from '~/app/open-age/car/components/component-list-base.component';
import { ComponentService } from '~/app/open-age/car/services';
import { ComponentTypePickerComponent } from '../component-type-picker/component-type-picker.component';

@Component({
  selector: 'car-component-list',
  templateUrl: './component-list.component.html',
  styleUrls: ['./component-list.component.css']
})
export class ComponentListComponent extends ComponentListBaseComponent {
  @Input()
  view: 'table' | 'list' | 'grid' = 'table';
  code: string;

  @Input()
  readonly: boolean;

  userCode: string;
  id: string;

  @ViewChild('list')
  list: ComponentTypePickerComponent;

  constructor(
    componentService: ComponentService,
    private uxService: UxService,
    public dialog: MatDialog
  ) {
    super(componentService, uxService);
    console.log(this.items);
  }
  addSalaryComponent() {
  }
  validate(): boolean {
    return true;
  }
  complete(): boolean | Observable<any> {
    return true;
  }

}
