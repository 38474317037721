import { ModelBase } from '../../core/models/model-base.model';

export class ComponentType extends ModelBase {
  name: string;
  description: string;
  type: string;

  frequency: {
    value: number,
    type: string
  };

  calculator: {
    engine: string,
    formula: string
  };
  config: any;
}
