import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UxService } from 'src/app/core/services/ux.service';
import { ConfirmDialogComponent } from '~/app/core/components/confirm-dialog/confirm-dialog.component';
import { AddLeaveDialogComponent } from '~/app/open-age-ng/ams/add-leave-dialog/add-leave-dialog.component';
import { LeaveBalance } from '~/app/open-age/ams/models/leave-balance.model';
import { LeaveType } from '~/app/open-age/ams/models/leave-type.model';
import { LeaveBalancesService } from '~/app/open-age/ams/services/leave-balances.service';
import { PageOptions } from '~/app/open-age/core/models';
import { PagerModel } from '~/app/open-age/core/structures';
import { EmployeeListBaseComponent } from '../../../open-age/directory/components/employee-list-base.component';
import { EmployeeService } from '../../../open-age/directory/services/employee.service';

@Component({
  selector: 'directory-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css'],
})
export class EmployeeListComponent extends EmployeeListBaseComponent {
  @Input()
  view: 'table' | 'list' | 'grid' = 'table';

  @Input()
  organization: string;
  dialogRef: any;

  constructor(public dialog: MatDialog,
    employeeService: EmployeeService,
    private uxService: UxService

  ) {
    super(employeeService, uxService);
  }

  onRemove(item): void {
    this.uxService.onConfirm().subscribe(() => {
      this.remove(item);
      this.uxService.showInfo('Deleted');
    });
  }

}
