import { ErrorHandler, Input, OnInit } from '@angular/core';
import { StatGrid } from 'src/app/open-age/insight/models/stat-grid.model';
import { JournalService } from 'src/app/open-age/insight/services/journal.service';
import { PagerModel, PagerOptions } from '~/app/open-age/core/structures';
import { Journal } from '../models/journal.model';
import { User } from '../models/user.model';

export class ActivitiesBaseComponent extends PagerModel<Journal> implements OnInit {
  @Input()
  user: User;

  activities: Journal[] = [];

  constructor(
    api: JournalService,
    errorHandler: ErrorHandler
  ) {
    super({ api, errorHandler });
  }

  ngOnInit() {
    this.fetch();
  }

}
