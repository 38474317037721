import { Component, Input, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services/ux.service';
import { ValidationService } from '~/app/core/services/validation.service';
import { RoleService } from '~/app/open-age/core/services';
import { FileUploaderBaseComponent } from '~/app/open-age/drive/components/file-uploader-base.component';
import { DocsService } from '~/app/open-age/drive/services';
import { FileUploaderZoneComponent } from '../file-uploader-zone/file-uploader-zone.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'drive-dp-component',
  templateUrl: './dp-component.component.html',
  styleUrls: ['./dp-component.component.css']
})
export class DpComponentComponent extends FileUploaderBaseComponent {

  static instancesCount = 0;

  @Input()
  item: any;

  @Input()
  type: string;

  inputId: string;

  constructor(
    auth: RoleService,
    fileService: DocsService,
    errorHandler: UxService,
    validator: ValidationService,
    sanitizer: DomSanitizer
  ) {
    super(auth, fileService, errorHandler, validator, sanitizer);
    this.inputId = `file-uploader-input-${DpComponentComponent.instancesCount++}`;
    this.types = ['image/png', 'image/jpeg', 'image/jpg'];
  }

}
