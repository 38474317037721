import { Component, Input, OnInit } from '@angular/core';
import { Release } from 'src/app/open-age/gateway/models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'release-summary-expandable',
  templateUrl: './release-summary-expandable.component.html',
  styleUrls: ['./release-summary-expandable.component.css']
})
export class ReleaseSummaryExpandableComponent implements OnInit {

  @Input()
  release: Release;

  @Input()
  isExpanded: boolean;

  constructor() { }

  ngOnInit() {
  }

}
