import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { PagerBaseComponent } from '~/app/open-age/core/structures';
import { Student } from '../models';
import { StudentService } from '../services';

export class StudentListBaseComponent extends PagerBaseComponent<Student> implements OnInit, OnChanges, OnDestroy {

  @Input()
  readonly = false;

  @Input()
  code: string;

  @Input()
  name: string;

  @Input()
  uniNo: string;

  @Input()
  fatherName: string;

  @Input()
  batch: string;

  @Input()
  course: string;

  @Input()
  college: string;

  constructor(
    api: StudentService,
    errorHandler: ErrorHandler
  ) {
    super({
      api,
      errorHandler,
      filters: [
        'name',
        'code',
        'institute',
        'university-no',
        'fatherName',
        'course',
        'batch'
      ],
      pageOptions: {
        limit: 10,
      },
    });
  }

  ngOnInit() {
    this.fetch().subscribe();

  }

  ngOnChanges() {
    this.filters.reset(false);

    if (this.code) {
      this.filters.set('code', this.code);
    }
    if (this.name) {
      this.filters.set('name', this.name);
    }

    if (this.uniNo) {
      this.filters.set('university-no', this.uniNo);
    }

    if (this.fatherName) {
      this.filters.set('fatherName', this.fatherName);
    }

    if (this.batch) {
      this.filters.set('batch', this.batch);
    }

    if (this.college) {
      this.filters.set('institute', this.college);
    }

    if (this.course) {
      this.filters.set('course', this.course);
    }
    this.fetch().subscribe();
  }

  ngOnDestroy(): void {
  }

}
