import { Component, OnInit } from '@angular/core';
import { UxService, ValidationService } from '~/app/core/services';
import { NewDivisionBaseComponent } from '~/app/open-age/directory/components/new-division-base.component';
import { DivisionService } from '~/app/open-age/directory/services';

@Component({
  selector: 'directory-division-wiz',
  templateUrl: './division-new.component.html',
  styleUrls: ['./division-new.component.css']
})
export class DivisionNewComponent extends NewDivisionBaseComponent {

  constructor(
    api: DivisionService,
    uxService: UxService
  ) {
    super(api, uxService);
  }

}
