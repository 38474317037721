import {
  ErrorHandler,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { PageOptions } from '../../core/models';
import { ReportType } from '../models';
import { ReportTypeService } from '../services/report-type.service';
import { WidgetDataService } from '../services/widget-data.service';

export class WidgetBaseComponent implements OnInit, OnChanges {
  @Input()
  reportType: ReportType;

  @Input()
  code: string;

  @Input()
  refreshSeconds = 0;

  @Input()
  options: PageOptions;

  @Output()
  selected: EventEmitter<any> = new EventEmitter();

  @Input()
  params: any = {};

  items: any[] = [];

  isProcessing = false;

  afterInitialization: () => void;
  afterProcessing: () => void;

  constructor(
    private api: WidgetDataService,
    private reportTypeService: ReportTypeService,
    private errorHandler: ErrorHandler
  ) { }
  ngOnInit(): void {
    // this.isProcessing = true;
    // this.getData();
  }

  ngOnChanges(changes): void {
    this.isProcessing = true;
    this.getData();
  }

  getData() {
    if (!this.reportType && this.code) {
      return this.reportTypeService.get(this.code).subscribe(
        (item) => {
          this.reportType = item;
          this.getData();
        },
        (err) => {
          this.isProcessing = false;
          this.errorHandler.handleError(err.message || err);
        }
      );
    }

    if (this.reportType) {
      if (this.afterInitialization) {
        this.afterInitialization();
      }

      this.params['reportTypeId'] = this.reportType.id;

      const filters = { ...this.params };

      if (
        filters.state &&
        (this.reportType.code !== 'covid-state-total' &&
          this.reportType.code !== 'covid-state-wise' &&
          this.reportType.code !== 'covid-district-wise')
      ) {
        delete filters.state;
      }

      if (filters.date && this.reportType.code !== 'covid-ind-day-status') {
        delete filters.date;
      }

      this.options = this.options || new PageOptions({});
      this.api.search(filters, this.options).subscribe(
        (p) => {
          this.items = p.items;
          this.isProcessing = false;
          if (this.afterProcessing) {
            this.afterProcessing();
          }
        },
        (err) => {
          this.isProcessing = false;
          this.errorHandler.handleError(err.message || err);
        }
      );

      if (this.refreshSeconds) {
        setTimeout(() => {
          this.getData();
        }, this.refreshSeconds * 1000);
      }
    }
  }

  getStyle(entity, value): any {
    const style = {};
    switch (entity) {
      case 'attendance-status':
        switch (value) {
          case 'present':
            style['color'] = '#28d716';
            break;
          case 'absent':
            style['color'] = '#db4d6b';
            break;
        }
    }
    return style;
  }

  formatValue(entity, value): any {
    switch (entity) {
      case 'attendance-status':
        switch (value) {
          case 'present':
            value = value[0].toUpperCase() + value.slice(1);
            break;
          case 'absent':
            value = value[0].toUpperCase() + value.slice(1);
            break;
        }
    }
    return value;
  }

  select(item) {
    this.selected.emit(item);
  }
}
