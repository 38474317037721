import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'auth-twitter-login-button',
  templateUrl: './twitter-login-button.component.html',
  styleUrls: ['./twitter-login-button.component.css'],
})
export class TwitterLoginButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
