import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BatchService, CourseService, DirectoryRoleService } from './services';
import { ContractorService } from './services/contractor.service';
import { DepartmentService } from './services/department.service';
import { DesignationService } from './services/designation.service';
import { DivisionService } from './services/division.service';
import { EmployeeService } from './services/employee.service';
import { OrganizationService } from './services/organization.service';
import { StudentService } from './services/student.service';
import { UserService } from './services/user.service';

const angularModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
];

const thirdPartyModules = [
];

const services = [
  EmployeeService,
  DepartmentService,
  DesignationService,
  DivisionService,
  ContractorService,
  OrganizationService,
  StudentService,
  BatchService,
  CourseService,
  UserService,
  DirectoryRoleService
];
const guards = [];
const sharedComponents = [];
const pipes = [];

@NgModule({
  imports: [
    ...angularModules,
    ...thirdPartyModules,
  ],

  exports: [
    ...angularModules,
    ...thirdPartyModules,
    ...sharedComponents,
    ...pipes,
  ],
  declarations: [
    ...sharedComponents,
    ...pipes
  ],
  providers: [
    ...services,
    ...guards
  ],
})
export class DirectoryModule { }
