import {
  ErrorHandler,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Page, PageOptions } from '../../core/models';
import { IPage, IPager } from '../../core/structures';
import { ReportType } from '../models';
import { ReportTypeService } from '../services/report-type.service';
import { WidgetDataService } from '../services/widget-data.service';

export class PagedWidgetBaseComponent implements OnInit, OnChanges, IPage<any>, IPager {

  @Input()
  reportType: ReportType;

  @Input()
  code: string;

  @Input()
  refreshSeconds = 0;

  @Input()
  options: PageOptions;

  @Output()
  selected: EventEmitter<any> = new EventEmitter();

  @Input()
  params: any = {};

  items: any[] = [];

  pager: IPager;

  isProcessing = false;

  afterInitialization: () => void;
  afterProcessing: () => void;

  constructor(
    private api: WidgetDataService,
    private reportTypeService: ReportTypeService,
    private errorHandler: ErrorHandler
  ) {
    this.pager = this;
  }

  currentPageNo: number;
  totalPages: number;
  total: number;
  stats: any;
  sort: string;
  desc: boolean;
  pageSize: number;
  limit: number;
  offset: number;

  ngOnInit(): void {
    // this.isProcessing = true;
    // this.getData();
  }

  ngOnChanges(changes): void {
    this.isProcessing = true;
    this.fetch();
  }

  fetch(options?: PageOptions) {
    if (!this.reportType && this.code) {
      return this.reportTypeService.get(this.code).subscribe(
        (item) => {
          this.reportType = item;
          this.fetch();
        },
        (err) => {
          this.isProcessing = false;
          this.errorHandler.handleError(err.message || err);
        }
      );
    }

    if (this.reportType) {
      if (this.afterInitialization) {
        this.afterInitialization();
      }

      this.params['reportTypeId'] = this.reportType.id;

      const filters = { ...this.params };

      if (
        filters.state &&
        (this.reportType.code !== 'covid-state-total' &&
          this.reportType.code !== 'covid-state-wise' &&
          this.reportType.code !== 'covid-district-wise')
      ) {
        delete filters.state;
      }

      if (filters.date && this.reportType.code !== 'covid-ind-day-status') {
        delete filters.date;
      }

      options = options || this.options || new PageOptions({});
      this.api.search(filters, options).subscribe(
        (page) => {
          this.items = page.items;
          this.total = page.total || (page.stats ? page.stats.total : null) || this.items.length;
          this.currentPageNo = page.pageNo;
          this.pageSize = page.pageSize;

          this.limit = options.limit;
          this.offset = options.offset;
          this.sort = options.sort;

          if (options.limit) {
            this.totalPages = Math.ceil(this.total / options.limit);
          } else {
            this.totalPages = 1;
          }
          this.isProcessing = false;
          if (this.afterProcessing) {
            this.afterProcessing();
          }
        },
        (err) => {
          this.isProcessing = false;
          this.errorHandler.handleError(err.message || err);
        }
      );

      if (this.refreshSeconds) {
        setTimeout(() => {
          this.fetch();
        }, this.refreshSeconds * 1000);
      }
    }
  }

  fetchData(options?: PageOptions) {
    this.isProcessing = true;
    const subject = new Subject<Page<any>>();
    if (this.afterInitialization) {
      this.afterInitialization();
    }

    this.params['reportTypeId'] = this.reportType.id;

    const filters = { ...this.params };

    if (
      filters.state &&
      (this.reportType.code !== 'covid-state-total' &&
        this.reportType.code !== 'covid-state-wise' &&
        this.reportType.code !== 'covid-district-wise')
    ) {
      delete filters.state;
    }

    if (filters.date && this.reportType.code !== 'covid-ind-day-status') {
      delete filters.date;
    }

    options = options || this.options || new PageOptions({});
    this.api.search(filters, options).subscribe(
      (page) => {
        this.items = page.items;
        this.total = page.total || (page.stats ? page.stats.total : null) || this.items.length;
        this.currentPageNo = page.pageNo;
        this.pageSize = page.pageSize;

        this.limit = options.limit;
        this.offset = options.offset;
        this.sort = options.sort;

        if (options.limit) {
          this.totalPages = Math.ceil(this.total / options.limit);
        } else {
          this.totalPages = 1;
        }
        this.isProcessing = false;
        if (this.afterProcessing) {
          this.afterProcessing();
        }
        subject.next(page);
      },
      (err) => {
        this.isProcessing = false;
        this.errorHandler.handleError(err.message || err);
        subject.error(err);
      }
    );
    return subject.asObservable();
  }

  getStyle(entity, value): any {
    const style = {};
    switch (entity) {
      case 'attendance-status':
        switch (value) {
          case 'present':
            style['color'] = '#28d716';
            break;
          case 'absent':
            style['color'] = '#db4d6b';
            break;
        }
    }
    return style;
  }

  formatValue(entity, value): any {
    switch (entity) {
      case 'attendance-status':
        switch (value) {
          case 'present':
            value = value[0].toUpperCase() + value.slice(1);
            break;
          case 'absent':
            value = value[0].toUpperCase() + value.slice(1);
            break;
        }
    }
    return value;
  }

  select(item) {
    this.selected.emit(item);
  }

  showPage(pageNo: number) {
    if (this.isProcessing) {
      return;
    }
    if (pageNo === -2) {
      pageNo = 1;
      return;
    }

    if (pageNo === -1) {
      pageNo = this.totalPages;
      return;
    }

    return this.fetchData(this.convertToPageOption(pageNo));
  }

  showPrevious() {
    if (this.isProcessing || this.currentPageNo <= 1) {
      return;
    }
    return this.showPage(this.currentPageNo - 1);
  }

  showNext() {
    if (this.isProcessing || this.totalPages <= this.currentPageNo) {
      return;
    }
    return this.showPage(this.currentPageNo + 1);
  }

  private convertToPageOption(pageNo: number) {
    const options = new PageOptions();
    if (this.options) {
      options.offset = (pageNo - 1) * this.options.limit;
      options.limit = this.options.limit;
      options.sort = this.options.sort;
    }
    return options;
  }

}
