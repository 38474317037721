import { Injectable } from '@angular/core';
import { IInputValidator } from 'src/app/open-age/core/services/input-validator.interface';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationService implements IInputValidator {

  constructor() { }

  textLength = 100;
  passwordMinLength = 6;
  passwordMaxLength = 12;
  portNumberLength = 6;
  pinCodeLength = 6;
  userCodeLength = 5;
  organizationCodeLength = 3;
  tenantCodeLength = 3;

  number: RegExp = /^[0-9]*$/;
  bssid: RegExp = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/;
  // tslint:disable-next-line:max-line-length
  email: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  numberWithTwoDecimals: RegExp = /^[0-9]+(\.[0-9]{1,2})?$/;
  // tslint:disable-next-line:max-line-length
  ipAddress: RegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  // tslint:disable-next-line:max-line-length
  mobile: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  phone: RegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  isMobileValid(value: string | number): string {
    const identity = `${value}`;
    if (!identity.match(/^\d{10}$/) &&
      !identity.match(/^(\+\d{1,3}[- ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
      return 'invalid mobile number';
    }
  }

  isBssidValid(value: string): string {
    if (!this.bssid.test(value)) {
      return 'invalid email';
    }
  }

  isEmailValid(value: string): string {
    if (!this.email.test(value)) {
      return 'invalid email';
    }
  }

  isIpAddressValid(value: string): string {
    if (!this.ipAddress.test(value)) {
      return 'invalid email';
    }
  }

  isUserCodeValid(value: string): string {
    if (!value) {
      return 'invalid code';
    }

    if (value.length < this.userCodeLength) {
      return `atleast ${this.userCodeLength} characters are required`;
    }
  }

  isOrganizationCodeValid(value: string): string {
    if (!value) {
      return 'invalid code';
    }

    if (value.length < this.organizationCodeLength) {
      return `atleast ${this.organizationCodeLength} characters are required`;
    }
  }

  isTenantCodeValid(value: string): string {
    if (!value) {
      return 'invalid code';
    }

    if (value.length < this.tenantCodeLength) {
      return `atleast ${this.tenantCodeLength} characters are required`;
    }
  }

  isPasswordValid(value: string): string {
    if (!value) {
      return 'invalid password';
    }

    if (value.length < this.passwordMinLength) {
      return `atleast ${this.passwordMinLength} characters are required`;
    }

    if (value.length > this.passwordMaxLength) {
      return `maximum allowed characters are  ${this.passwordMaxLength}`;
    }
  }

  noWhitespaceValidator(control: FormControl): { [key: string]: boolean } | null {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
