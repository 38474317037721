import { ModelBase } from '../../core/models/model-base.model';
import { SurveyCategory } from './survey-category';

export class PracticeSurveyCategory extends ModelBase {

    category: SurveyCategory

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }

        this.id = obj.id;
        this.category = new SurveyCategory(obj.category || {});

    }
}
