import { Component, Input, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services';
import { ComponentTypeListBaseComponent } from '../../../open-age/car/components/component-type-list-base.component';
import { ComponentTypeService } from '../../../open-age/car/services/component-type.service';

@Component({
  selector: 'car-component-type-list',
  templateUrl: './component-type-list.component.html',
  styleUrls: ['./component-type-list.component.css']
})
export class ComponentTypeListComponent extends ComponentTypeListBaseComponent {
  @Input()
  view: 'table' | 'list' | 'grid' = 'table';

  constructor(
    componentTypeService: ComponentTypeService,
    uxService: UxService
  ) {
    super(componentTypeService, uxService);
  }

}
