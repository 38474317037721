import { ModelBase } from '../../core/models/model-base.model';
import { DeviceCategory } from './device-category.model';
import { DeviceType } from './device-type.model';

export class Device extends ModelBase {
  category: DeviceCategory;
  machine: DeviceType;
  name: string;
  type: string;
  mute: Array<{
    start: any;
    end: any
  }> = [];
  ip: string;
  port: string;
  bssid: string;
  interval: string;
  status: string;

  constructor(obj?: any) {
    super();

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.code = obj.code;
    this.name = obj.name;
    this.type = obj.type;
    this.ip = obj.ip;
    this.port = obj.port;
    this.bssid = obj.bssid;
    this.interval = obj.interval;
    this.status = obj.status;

    if (obj.category) {
      this.category = obj.category;
    }

    if (obj.machine) {
      if (typeof obj.machine === 'string') {
        this.machine = new DeviceType();
        this.machine.id = obj.machine;
      } else {
        this.machine = new DeviceType(obj.machine);
      }
    }
  }
}
