
export class Profile {
  firstName: string;
  lastName: string;
  fatherName: string;

  constructor(obj?: any) {

    if (!obj) {
      return;
    }
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.fatherName = obj.fatherName;
  }
}
