import { HttpHeaders } from '@angular/common/http';
import { Component, ErrorHandler, Input, OnInit } from '@angular/core';
import { CompensationsDetailBaseComponent } from '~/app/open-age/car/components/compensations-detail-base.component';
import { CompensationsService } from '~/app/open-age/car/services/compensations.service';
import { RoleService } from '~/app/open-age/core/services';
import { DocumentService } from '~/app/open-age/send-it/services';

@Component({
  selector: 'car-my-payslip',
  templateUrl: './my-payslip.component.html',
  styleUrls: ['./my-payslip.component.css']
})
export class MyPayslipComponent extends CompensationsDetailBaseComponent {

  constructor(
    service: CompensationsService,
    errorHandler: ErrorHandler,
    private docService: DocumentService,
    private auth: RoleService
  ) {
    super(service, errorHandler);
  }

  downloadPaySlip() {
    this.docService.downloadByModel('paySlip-aqua', {
      earnings: this.compensation.earnings,
      deductions: this.compensation.deductions,
      totalEarning: this.earningValue,
      totalDeduction: this.deductionValue,
      netSalary: this.netSalaryValue
    }, new HttpHeaders({
      'Content-type': 'application/json',
      'authorization': this.auth.currentRole().token
    })).subscribe((response) => {
      if (window.navigator.msSaveOrOpenBlob) { // IE Browser
        window.navigator.msSaveOrOpenBlob(response, `Pay-Slip.pdf`);
      } else {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(response);
        a.download = `Pay-Slip.pdf`;
        document.body.appendChild(a);
        a.click();
      }
      this.isProcessing = false;
    }, (err) => {
      this.isProcessing = false;
    });
  }
}
