import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Conversation } from 'src/app/open-age/send-it/models';
import { Action } from '~/app/open-age/core/structures';
import { Entity } from '../../../open-age/core/models';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit, OnChanges {

  entity: Entity;
  conversation: Conversation;

  section: 'files' | 'comment' | 'tasks' = 'comment';

  opened = true;

  constructor(
    private uxService: UxService
  ) {
    this.uxService.entityChanges.subscribe((item) => {
      this.entity = item;
      if (this.entity) {
        this.conversation = new Conversation();
        this.conversation.entity = this.entity;
        this.conversation.type = 'entity';
        this.opened = true;
      } else {
        this.opened = false;
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges(): void {
  }

  showFiles() {
    this.opened = true;
    this.section = 'files';
  }

  showTasks() {
    this.opened = true;
    this.section = 'tasks';
  }

  showNotes() {
    this.opened = true;
    this.section = 'comment';
  }

  close() {
    this.opened = false;
  }

  open() {
    this.opened = true;
  }

}
