import { HttpClient } from '@angular/common/http';
import { UxService } from '~/app/core/services';
import { GenericApi } from '~/app/open-age/core/services';
import { RoleService } from '../../core/services';

export class TelepainApiBase<TModel> extends GenericApi<TModel> {
    constructor(
        collection: string,
        http: HttpClient,
        auth: RoleService,
        uxService: UxService
    ) {
        super(http, 'telepain', { collection, auth, errorHandler: uxService });
    }
}
