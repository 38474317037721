import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IBreadcrumbHandler } from '~/app/open-age/core/services/breadcrumb-handler.interface';
import { ITitleHandler } from '~/app/open-age/core/services/title-handler.interface';
import { Link } from '~/app/open-age/core/structures';
import { RoleService } from '../../open-age/core/services/role.service';

@Injectable({
  providedIn: 'root'
})
export class NavService implements
  ITitleHandler,
  IBreadcrumbHandler {

  private _title = new Subject<string>();

  private _nav = new Subject<{
    page: Link,
    is: (path: string) => boolean,
    get: (key: string, route: ActivatedRoute) => string
  }>();
  private _navsSubject = new Subject<Link[]>();
  private _breadcrumb = new Subject<Link[]>();

  private _navs: Link[];

  private _currentPage: Link;
  private _breadcrumbLinks: Link[] = [];
  private _breadcrumbCodes: Array<{
    code: string,
    path: string,
    item: Link,
    parent: Link
  }> = [];

  navChanges = this._nav.asObservable();
  navsChanges = this._navsSubject.asObservable();
  breadcrumbChanges = this._breadcrumb.asObservable();
  title = this._title.asObservable();

  currentUrl: string;

  constructor(
    private router: Router,
    private location: Location,
    private auth: RoleService

  ) {

    this.router.events.subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        const url = (event as NavigationEnd).url.toLowerCase();

        if (this.currentUrl && this.currentUrl === url) {
          return;
        }

        const nav = this._breadcrumbCodes.find((i) => this.router.isActive(i.path, true));

        const link = nav ? nav.item : null;

        if (link) {
          this.setPage(link);
        }

        if ((link && this._currentPage && this._currentPage.code === link.code) ||
          (!this._currentPage && link) ||
          (this._currentPage && !link)) {
          this._currentPage = link;
          this._nav.next(this.getNav(this._currentPage, this.router));
        }
      }
    });
  }

  private createBreadcrumbs(links: any[], parent?: Link): Link[] {
    if (!links || !links.length) {
      return [];
    }

    for (const link of links) {

      if (link.items && link.items.length) {
        this.createBreadcrumbs(link.items, link);
      }

      const item = new Link(link);

      let path = '';

      if (item.url) {
        path = item.url;
      } else if (item.routerLink && item.routerLink.length) {
        item.routerLink.forEach((l) => {
          path = l.startsWith('/') ? `${path}${l}` : `${path}/${l}`;
        });
      }
      this._breadcrumbCodes.push({
        code: link.code,
        path,
        item,
        parent
      });
    }
  }

  private getNav(page: Link, router: Router) {
    return {
      page,
      is: (key: string, params?: any) => {

        if (key.startsWith('/')) {
          return router.isActive(key, true);
        }

        const item = this._breadcrumbCodes.find((n) => n.code.toLowerCase() === key.toLowerCase());

        if (!item) {
          return false;
        }
        params = params || {};
        const path = item.item.path.split('/').map((p) => {
          if (p.startsWith(':')) {
            return params[p.substring(1)];
          }
        }).join('/');

        return router.isActive(path, true);
      },
      get: (key: string, route: ActivatedRoute) => {
        const snapshot = route.snapshot;

        if (snapshot.paramMap.has(key)) {
          return snapshot.paramMap.get(key);
        }

        if (snapshot.queryParamMap.has(key)) {
          return snapshot.queryParamMap.get(key);
        }

        return null;
      }
    };
  }

  public setNav(navs: Link[]) {
    this._navs = navs;

    this.createBreadcrumbs(this._navs);
    this._navsSubject.next(this._navs);

    this.router.events.subscribe((i) => {
      if (i instanceof NavigationEnd) {
        let url = (i as NavigationEnd).url.toLowerCase();

        if (this.currentUrl && this.currentUrl === url) {
          return;
        }
        this.currentUrl = url;

        if (url.indexOf('?') !== -1) {
          url = url.split('?')[0];
        }
        const nav = this._breadcrumbCodes.find((b) => (b.path || '').toLowerCase() === url);

        if (nav && nav.item && nav.item.code) {
          this._nav.next(this.getNav(nav.item, this.router));
        } else {
          this._nav.next(this.getNav(null, this.router));
        }
      }
    });
  }

  getNavs(): Link[] {
    return this._navs;
  }

  setPage(code: string | Link) {
    if (!code) {
      return;
    }
    const obj: Link[] = [];

    const linkCode = typeof code === 'string' ? code : code.code;

    const link = this._breadcrumbCodes.find((l) => l.code && linkCode.toLowerCase() === l.code.toLowerCase());

    if (!link) {
      return;
    }

    const createLink = (item: { code: string, item: Link, parent: Link }) => {
      if (item.parent) {
        const parent = this._breadcrumbCodes.find((l) =>
          l.code && item.parent.code &&
          item.parent.code.toLowerCase() === l.code.toLowerCase());
        createLink(parent);
      }
      obj.push(item.item);
    };

    createLink(link);

    this.setBreadcrumb(obj);
    this.setTitle(link.item.title);

    return link;
  }

  private toUrl(path, route: ActivatedRoute) {
    const paramMap = route.snapshot.paramMap;

    return path.split('/').map((p) => {
      if (p.startsWith(':')) {
        const key = p.substring(1);

        if (paramMap.has(key)) {
          p = paramMap.get(key);
        }
      }
      return p;
    }).join('/');
  }

  private getLink(path: string, route: ActivatedRoute): Link {

    if (!path) {
      return;
    }
    const url = this.toUrl(path, route);
    const item = this._breadcrumbCodes.find((l) => l.path && (
      url.toLowerCase() === l.path.toLowerCase() ||
      path.toLowerCase() === l.path.toLowerCase()));

    if (item && item.item) {
      return item.item;
    }

    const parts = path.split('/');

    const parentParts = [];

    for (let index = 0; index < parts.length - 1; index++) {
      parentParts.push(parts[index]);
    }

    return this.getLink(parentParts.join('/'), route);
  }

  register(path: string, route: ActivatedRoute, change: (isCurrent: boolean, params: { get: (key) => string }) => void): Link {
    if (!path) {
      return;
    }
    const getParam = (key: string) => {
      const snapshot = route.snapshot;

      if (snapshot.paramMap.has(key)) {
        return snapshot.paramMap.get(key);
      }

      if (snapshot.queryParamMap.has(key)) {
        return snapshot.queryParamMap.get(key);
      }

      return null;
    };

    this.navChanges.subscribe((n) => {
      change(this.router.isActive(this.toUrl(path, route), true), { get: getParam });
    });

    change(this.router.isActive(this.toUrl(path, route), true), { get: getParam });

    const item = this.getLink(path, route);

    if (item) {
      if (item.permissions && item.permissions.length && !this.auth.hasPermission(item.permissions)) {
        this.router.navigate([`/home/errors/access-denied`], { queryParams: { path } });
        return;
      }
      this.setPage(item);
      return item;
    }

    this.router.navigate([`/home/dashboard/default`], { queryParams: { path } });
  }

  // get(key: string, onMatch?: () => void) {
  //   if (!key) {
  //     return;
  //   }

  //   if (key.startsWith('/')) {
  //     const navByPath = this._breadcrumbCodes.find((l) => l.path && key.toLowerCase() === l.path.toLowerCase());

  //     return {
  //       page: navByPath ? navByPath.item : null,
  //       is: (path: string) => this.router.isActive(path, true),
  //       get: this.getParam,
  //       change: this.navChanges
  //     };
  //   }

  //   const navByCode = this._breadcrumbCodes.find((l) => l.code && key.toLowerCase() === l.code.toLowerCase());

  //   return {
  //     page: navByCode ? navByCode.item : null,
  //     is: (path: string) => this.router.isActive(path, true),
  //     get: this.getParam,
  //     change: this.navChanges
  //   };

  // }

  updateCurrent(title: string) {

  }

  // isCurrentPage(code: string | Link): boolean {

  //   if (this._currentPage) {
  //     if (typeof code === 'string') {
  //       return this._currentPage.code === code;
  //     } else {
  //       return this._currentPage.code === code.code;
  //     }
  //   }
  //   const item = typeof code === 'string' ? this.get(code) : code;

  //   return this.router.isActive(item.path, true);

  //   // let url = this.router.url;

  //   // if (url.indexOf('?') !== -1) {
  //   //   url = url.split('?')[0];
  //   // }

  //   // return item.path === url;
  // }

  goto(key: string | Link | string[], params?: any) {
    this.auth.toogleIdle()
    if (!key) {
      return;
    }

    let nav: Link;
    if (typeof key === 'string') {
      if (key.startsWith('/')) {
        return this.router.navigate([key], params);
      } else {
        const item = this._breadcrumbCodes.find((l) => l.code && key.toLowerCase() === l.code.toLowerCase());
        if (item) {
          nav = item.item;
        } else {
          this.gotoParent(key, params);
        }
      }
    } else if (Array.isArray(key)) {
      this.router.navigate(key, params);
    } else {
      nav = key;
    }

    if (nav.routerLink && nav.routerLink.length) {
      this.router.navigate(nav.routerLink, params);
    } else if (nav.url) {
      this.router.navigateByUrl(nav.url);
    } else {
      this.back();
    }
  }

  gotoParent(code: string | Link, params?: any) {
    if (!code) {
      return;
    }
    const linkCode = typeof code === 'string' ? code : code.code;
    const link = this._breadcrumbCodes.find((l) => l.code && linkCode.toLowerCase() === l.code.toLowerCase());

    if (!link || !link.parent) {
      this.back();
    }

    const parent = link.parent;

    if (parent.routerLink && parent.routerLink.length) {
      this.router.navigate(parent.routerLink, params);
    } else if (parent.url) {
      this.router.navigateByUrl(parent.url);
    } else {
      this.back();
    }
  }

  setBreadcrumb(links: any[]) {
    const items: Link[] = [];
    links.forEach((link) => {
      const item = new Link(link);
      item.isActive = false;
      items.push(item);
    });
    if (items.length > 0) {
      items[items.length - 1].isActive = true;
    }

    this._breadcrumbLinks = items;
    this._breadcrumb.next(this._breadcrumbLinks);
  }

  pushBreadcrumb(obj: any) {
    const link = new Link(obj);
    link.isActive = true;

    this._breadcrumbLinks.forEach((i) => i.isActive = false);
    this._breadcrumbLinks.push(link);
    this._breadcrumb.next(this._breadcrumbLinks);
  }

  popBreadcrumb() {
    if (this._breadcrumbLinks.length) {
      this._breadcrumbLinks.pop();
    }

    this._breadcrumb.next(this._breadcrumbLinks);
  }

  replaceBreadcrumb(obj: any) {
    const link = new Link(obj);
    if (this._breadcrumbLinks.length) {
      this._breadcrumbLinks.pop();
    }

    this._breadcrumbLinks.push(link);

    this._breadcrumb.next(this._breadcrumbLinks);
  }

  resetBreadcrumb() {
    this._breadcrumbLinks = [];
    this._breadcrumb.next(this._breadcrumbLinks);
  }

  back() {
    this.location.back();
  }

  reset() {
    this.resetTitle();
    this.resetBreadcrumb();
  }

  setTitle(title: string) {
    this._title.next(title);
  }

  resetTitle() {
    this._title.next('');
  }

}
