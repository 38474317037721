import { ModelBase } from '../../core/models/model-base.model';

export class SurveyCategory extends ModelBase {

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }

        this.id = obj.id;
        this.name = obj.name;
    }
}
