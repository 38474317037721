import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UxService } from 'src/app/core/services/ux.service';
import { RoleListBaseComponent } from '~/app/open-age/directory/components/role-list-base.component';
import { DirectoryRoleService } from '~/app/open-age/directory/services';

@Component({
  selector: 'directory-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css'],
})
export class RoleListComponent extends RoleListBaseComponent {
  @Input()
  view: 'table' | 'list' | 'grid' = 'table';
  dialogRef: any;
  showInactive: boolean = false;

  constructor(public dialog: MatDialog,
    service: DirectoryRoleService,
    private uxService: UxService
  ) {
    super(service, uxService);
  }

  onRemove(item): void {
    this.uxService.onConfirm().subscribe(() => {
      this.remove(item);
      this.uxService.showInfo('Deleted');
    });
  }

  changeStatus(event) {
    if (this.showInactive) {
      this.status = "inactive"
    } else {
      this.status = "active"
    }
    this.currentPageNo = 1
    this.refresh()
  }

}
