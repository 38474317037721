import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UxService } from '~/app/core/services';
import { PageOptions } from '~/app/open-age/core/models';
import { PagedWidgetBaseComponent } from '~/app/open-age/insight/components/paged-widget.base.component';
import { ReportTypeService } from '~/app/open-age/insight/services/report-type.service';
import { WidgetDataService } from '~/app/open-age/insight/services/widget-data.service';

@Component({
  selector: 'insight-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.css']
})
export class TableWidgetComponent extends PagedWidgetBaseComponent {

  @Input()
  noPaging = true;
  
  headArray = [];
  constructor(private router: Router, api: WidgetDataService, reportTypeService: ReportTypeService, uxService: UxService) {
    super(api, reportTypeService, uxService);
    if(this.noPaging) {
      this.options = new PageOptions({}) 
    } else {
      this.options = new PageOptions({ limit: 5 })
    }
    this.afterInitialization = () => {
      if (this.reportType && this.reportType.columns) {
        this.headArray = this.reportType.columns.map((c) => c.key);
      }
    };
  }
}
