import { Component, ErrorHandler, Input } from '@angular/core';
import { UxService } from '~/app/core/services';
import { CompensationsBaseComponent } from '~/app/open-age/car/components/compensations-base.component';
import { Compensations } from '~/app/open-age/car/models/compensations.model';
import { CompensationsService } from '~/app/open-age/car/services/compensations.service';

@Component({
  selector: 'car-compensations-list',
  templateUrl: './compensations-list.component.html',
  styleUrls: ['./compensations-list.component.css']
})
export class CompensationsListComponent extends CompensationsBaseComponent {

  @Input()
  view: 'table' | 'list' | 'grid' = 'table';

  checkAll = false;

  constructor(
    service: CompensationsService,
    errorHandler: ErrorHandler,
    private uxService: UxService
  ) {
    super(service, errorHandler);
  }

  getEarning(compensation) {
    let amount = 0;
    if (compensation.earnings.length) {
      compensation.earnings.forEach((data) => {
        if (data.amount) {
          amount = data.amount + amount;
        }
      });
    }
    return amount;
  }

  getDeductions(compensation) {
    let amount = 0;
    if (compensation.deductions.length) {
      compensation.deductions.forEach((data) => {
        if (data.amount) {
          amount = data.amount + amount;
        }
      });
    }
    return amount;
  }

  changeEvent(item) {
    item.isSelected = true;
  }

  selectAll(value) {
    this.checkAll = !value;
    this.items.forEach((item) => {
      item.isSelected = !value;
    });
  }

  updateAll() {
    if (this.items && this.items.length) {
      let count = 0;
      this.items.forEach((item) => {
        if (item.status !== 'publish') {
          item.status = 'publish';
          this.update(item);
          count++;
        }
        this.uxService.handleError(`${count} components are Publish`);
      });
    } else {
      this.uxService.handleError('No component to Publish');
    }

  }

  updateItem(item) {
    item.status = 'Active';
    this.update(item);
  }

  getNetSalary(item) {
    return this.getEarning(item) - this.getDeductions(item);
  }

}
