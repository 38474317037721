import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UxService } from '~/app/core/services';
import { RoleService } from '../../core/services';
import { Organization } from '../models/organization.model';
import { WelcomeApi } from './welcome-api.base';

@Injectable()
export class OrganizationService extends WelcomeApi<Organization> {
  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('organizations', http, roleService, uxService);
  }
}
