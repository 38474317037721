import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UxService } from 'src/app/core/services/ux.service';
import { Provider } from 'src/app/open-age/send-it/models';
import { ProviderService } from 'src/app/open-age/send-it/services';
import { Action, Menu, PagerBaseComponent, PagerOptions } from '~/app/open-age/core/structures';

@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrls: ['./provider-list.component.css']
})
export class ProviderListComponent extends PagerBaseComponent<Provider> implements OnInit {

  constructor(
    private api: ProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private uxService: UxService
  ) {
    super({
      api,
      errorHandler: uxService
    });
  }
  ngOnInit(): void {
    this.fetch();
  }
}
