import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WizStepBaseComponent } from '~/app/open-age/core/structures/wiz/wiz-step-base.component';

import { query } from '@angular/core/src/render3';
import { UxService } from '~/app/core/services';
import { BiometricListBaseComponent } from '~/app/open-age/ams/components/biometric-list-base.component';
import { Biometric } from '~/app/open-age/ams/models/biometric.model';
import { Device } from '~/app/open-age/ams/models/device.model';
import { BiometricService } from '~/app/open-age/ams/services/biometric.service';
import { DeviceService } from '~/app/open-age/ams/services/device.service';
import { Employee } from '~/app/open-age/directory/models/employee.model';
import { Task } from '~/app/open-age/gateway/models';

@Component({
  selector: 'directory-biometric-editor',
  templateUrl: './biometric-editor.component.html',
  styleUrls: ['./biometric-editor.component.css']
})
export class BiometricEditorComponent extends BiometricListBaseComponent implements OnInit {

  @Input()
  employee: Employee;

  @Input()
  usercode: string;

  @Input()
  readOnly: boolean;

  devices: Device[];

  isLoading = false;
  biometrics: Biometric[] = [];
  canEnable = false;

  hasPendingTasks = true;

  pendingTasks: Task[] = [];

  constructor(
    private api: BiometricService,
    private deviceService: DeviceService,
    private uxservice: UxService
  ) {
    super(api, uxservice);
  }

  ngOnInit() {

    let query: {
      category: 'biometric';
    };
    this.deviceService.search(query).subscribe((data) => {

      this.devices = data.items;
    });

  }

  validate(): boolean {
    return true;
  }
  complete(): boolean | Observable<any> {
    return true;
  }

}
