import { ModelBase } from '../../core/models/model-base.model';
import { Level } from '../enums/level.enum';
import { TemplateAction } from './template-action.model';
import { TemplateConfig } from './template-config.model';
import { TemplateDataSource } from './template-data-source.model';

export class Template extends ModelBase {
  name: string;
  subject: string;
  body: string;
  level: Level;
  attachment: Template;

  logo: string; // logo of tenant or organization
  dp: string; // display picture of user
  isHidden: boolean; // For hidden operations
  category: string;

  config: TemplateConfig;

  dataSource: TemplateDataSource;

  actions: TemplateAction[];

  constructor(obj?: any) {
    super();

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.code = obj.code;
    this.status = obj.status;
    this.timeStamp = obj.timeStamp;

    this.name = obj.name;
    this.body = obj.body;
    this.subject = obj.subject;
    this.level = obj.level;

    this.config = new TemplateConfig(obj.config || {});

    if (obj.dataSource) {
      this.dataSource = new TemplateDataSource(obj.dataSource);
    }
  }
}
