import { UxService } from './../../../core/services/ux.service';
import { PatientService } from '~/app/open-age/telepain/services/patient.service';
import { Component, OnInit, Inject, HostListener, ErrorHandler } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-patient-import',
  templateUrl: './patient-import.component.html',
  styleUrls: ['./patient-import.component.css']
})
export class PatientImportComponent implements OnInit {

  file: File;
  inputId: string = 'patient-upload';
  types: Array<string> = ['text/csv', 'application/vnd.ms-excel', '.csv'];

  readyForDrop = false;
  fileDropped = false;
  isProcessing = false;

  constructor(
    public dialogRef: MatDialogRef<PatientImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private patientService: PatientService,
    private uxService: UxService,
  ) { }

  ngOnInit() {
  }

  close(val: boolean) {
    this.dialogRef.close(val);
  }

  onImport(): void {
    if (!this.file) {
      return this.uxService.handleError(`Please Select File`);
    }
    if (this.types && this.types.length) {
      let match = false;
      if (this.types.find((i) => i === this.file.type)) {
        match = true;
      }
      if (!match) {
        return this.uxService.handleError(`Only ${this.types.join()} are supported`);
      }
    }

    this.isProcessing = true;
    let uploadFile: any = this.file;
    if (this.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      uploadFile = new Blob([this.file], { type: 'text/csv' })
    }
    this.patientService.upload(uploadFile, `bulk`, { name: this.file.name }).subscribe((response) => {
      this.isProcessing = false;
      this.uxService.handleLongError(response.message)
      this.close(true);
    }, (err) => {
      this.isProcessing = false;
      this.close(false);
    })
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const items = evt.dataTransfer.items;
    if (items.length > 0) {
      this.readyForDrop = true;
      // do some stuff here
    }
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.readyForDrop = false;
    // do some stuff
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    this.readyForDrop = false;
    if (files.length > 0) {
      this.file = files[0]
    }
  }

  download(fileType: string) {
    window.open(`assets/files/patients.${fileType}`, "_blank")
  }

}
