import { ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RoleService } from '../../core/services';
import { IInputValidator } from '../../core/services/input-validator.interface';
import { Employee, Organization, Profile, Role, User } from '../../directory/models';
import { Student } from '../../directory/models/student.model';
import { OrganizationService } from '../../directory/services/organization.service';

export class SignUpBaseComponent implements OnInit, OnChanges {

  @Input()
  type: 'individual' | 'employee' | 'student' = 'individual';

  @Input()
  oneStep = true;

  @Output()
  created: EventEmitter<void> = new EventEmitter();

  @Output()
  success: EventEmitter<Role> = new EventEmitter();

  @Output()
  failure: EventEmitter<Error> = new EventEmitter();

  userId: string;
  otp = {
    char_1: '',
    char_2: '',
    char_3: '',
    char_4: '',
    char_5: '',
    char_6: '',
  };

  idType = 'email';

  email: string;
  mobile: string;
  rollNo: string;
  employeeNo: string;
  code: string;
  organization: Organization;

  profile: Profile;
  employee: Employee;
  student: Student;
  password: string;

  orgCodeError: string;

  employeeNoError: string;
  rollNoError: string;
  mobileError: string;
  emailError: string;
  codeError: string;

  error: string;

  isProcessing = false;
  role: Role;

  orgExists: boolean;
  createNewOrg = false;
  domain = '';

  constructor(
    private auth: RoleService,
    private organizations: OrganizationService,
    private validator: IInputValidator,
    private errorHandler: ErrorHandler
  ) {
  }

  ngOnInit() {

    const tenant = this.auth.currentTenant();
    const host = tenant.host || window.location.host;
    const parts = host.split('.');
    this.domain = '';
    for (let index = 1; index < parts.length; index++) {
      this.domain = `${this.domain}.${parts[index]}`;
    }
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }
  // next() {
  //   this.isProcessing = true;
  //   this.auth.sendOtp(this.email, this.mobile, this.code).subscribe((user) => {
  //     this.isProcessing = false;
  //     this.userId = user.id;
  //   }, (error) => {
  //     this.error = error.message;
  //     this.isProcessing = false;
  //     this.errorHandler.handleError(error);
  //   });
  // }

  next() {
    this.isProcessing = true;
    this.auth.signup(this.email || this.mobile).subscribe((user) => {
      this.userId = user.id;
      this.isProcessing = false;
      this.created.emit();
    }, (error) => {
      this.isProcessing = false;
      this.error = error.message;
      this.errorHandler.handleError(error.message);
    });
  }

  private init() {
    this.profile = new Profile();
    this.student = null;
    this.employee = null;
    if (this.type !== 'individual') {
      this.organization = new Organization();
      if (this.type === 'employee') {
        this.employee = new Employee();
      } else if (this.type === 'student') {
        this.student = new Student();
      }
    } else {
      this.organization = null;
    }
  }

  validateOrganization(code: string) {
    this.createNewOrg = undefined;
    this.orgExists = undefined;
    this.orgCodeError = this.validator.isOrganizationCodeValid(code);
    if (this.orgCodeError) {
      return;
    }
    this.organizations.get(`${code}/summary`).subscribe((organization) => {
      if (organization) {
        this.organization = organization;
        this.orgExists = true;
      } else {
        this.orgExists = false;
        this.organization = new Organization();
        this.organization.id = 'new';
        this.organization.code = code;
      }
    }, (err) => {
      this.organization = new Organization();
    });
  }

  validateEmail() {
    this.emailError = this.validator.isEmailValid(this.email);
    if (this.emailError) {
      return;
    }
    this.auth.exists(this.email, 'email').subscribe((result) => {

      if (!result.exists) {
        this.emailError = '';
        return;
      }

      const error = new Error();

      error.name = 'EMAIL_TAKEN';
      error.message = `${this.email} is taken`;

      this.emailError = error.message;
      this.failure.next(error);
    });

  }

  validateMobile() {
    this.mobileError = this.validator.isMobileValid(this.mobile);
    if (this.mobileError) {
      return;
    }
    this.auth.exists(this.mobile, 'mobile').subscribe((data) => {
      if (data.exists) {
        this.mobileError = `${this.mobile} is taken`;

      } else {
        this.mobileError = '';

      }
    });
  }

  validateCode() {
    this.codeError = undefined;
    this.auth.exists(this.code, 'code').subscribe((exist) => {
      if (exist) {
        this.codeError = `${this.code} is taken`;
      } else {
        this.codeError = '';
      }
    });
  }

  validateRollNo() {
    this.rollNoError = undefined;
    // this.auth.exists(this.code, 'code').subscribe((exist) => {
    //   if (exist) {
    //     this.codeError = `${this.code} is taken`;
    //   } else {
    //     this.codeError = '';
    //   }
    // });
  }

  validateEmployeeNo() {
    this.employeeNoError = undefined;
    // this.auth.exists(this.code, 'code').subscribe((exist) => {
    //   if (exist) {
    //     this.codeError = `${this.code} is taken`;
    //   } else {
    //     this.codeError = '';
    //   }
    // });
  }

  // next() {
  //   this.isProcessing = true;
  //   this.auth.signup(this.email || this.mobile).subscribe((user) => {
  //     this.userId = user.id;
  //     this.isProcessing = false;
  //     this.created.emit();
  //   }, (error) => {
  //     this.isProcessing = false;
  //     this.error = error.message;
  //     this.errorHandler.handleError(error.message);
  //   });
  // }

  confirm() {
    const otp = `${this.otp.char_1}${this.otp.char_2}${this.otp.char_3}${this.otp.char_4}${this.otp.char_5}${this.otp.char_6}`;
    this.isProcessing = true;
    this.auth.initPassword({
      id: this.userId,
      profile: this.profile
    }, otp, this.password).subscribe((role) => {
      if (this.oneStep && this.organization) {
        return this.join();
      }
      this.isProcessing = false;
      this.role = role;
      this.success.next(role);
    }, (error) => {
      this.isProcessing = false;
      this.error = error.message;
      this.errorHandler.handleError(error.message);
    });
  }

  join() {
    this.isProcessing = true;

    if (this.organization && this.organization.id === 'new') {
      this.organization.id = null;
    }

    this.auth.newRole(this.profile, this.type, this.organization).subscribe((role) => {
      this.role = role;
      this.isProcessing = false;
      this.success.emit(role);
    }, (error) => {
      this.isProcessing = false;
      this.error = error.message;
      this.errorHandler.handleError(error.message);
    });
  }

  cancel() {
    this.userId = null;
    this.role = null;
  }
}
