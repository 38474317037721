import { ModelBase } from '../../core/models/model-base.model';
import { ReportArea } from './report-area.model';
import { ReportColumn } from './report-column.model';
import { ReportParam } from './report-param.model';
export class ReportType extends ModelBase {
  name: string;
  icon?: string;
  description?: string;
  view?: string;
  area: ReportArea;
  config: any;
  graph: any;
  container: {
    code?: string
    style?: {}
    class?: {}
  };
  widget: {
    yAxisLabel: string,
    xAxisLabel: string
  };

  params: ReportParam[] = [];
  columns: ReportColumn[] = [];

  status: string;
  provider: string;
  permissions?: string[];

  constructor(obj?: any) {
    super();
    if (!obj) {
      return;
    }
    this.id = obj.id;
    this.code = obj.code;
    this.name = obj.name;

  }
}
