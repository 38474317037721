import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatTableModule
} from '@angular/material';
import { CarModule } from '~/app/open-age/car/car.module';
import { MyPayslipComponent } from '../../open-age-ng/car/my-payslip/my-payslip.component';
import { SharedModule } from '../../shared/shared.module';
import { CompensationsDetailComponent } from './compensations-detail/compensations-detail.component';
import { CompensationsListComponent } from './compensations-list/compensations-list.component';
import { ComponentEditorComponent } from './component-editor/component-editor.component';
import { ComponentListComponent } from './component-list/component-list.component';
import { ComponentTypeEditorComponent } from './component-type-editor/component-type-editor.component';
import { ComponentTypeListComponent } from './component-type-list/component-type-list.component';
import { ComponentTypePickerComponent } from './component-type-picker/component-type-picker.component';

const components = [
  ComponentTypeListComponent, ComponentTypeEditorComponent, ComponentTypePickerComponent,
  ComponentListComponent, ComponentEditorComponent, CompensationsListComponent, CompensationsDetailComponent, MyPayslipComponent
];
const thirdPartyModules = [
  MatIconModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatCardModule,
  MatListModule,
  MatTableModule,
];
const services = [];
const guards = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CarModule,
    SharedModule,
    ...thirdPartyModules,
  ],
  declarations: [...components, ...pipes, CompensationsDetailComponent],
  exports: [...components, ...pipes],
  providers: [...services, ...guards],
})
export class OaCarModule { }
