import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DetailBase } from '~/app/open-age/core/structures';
import { IInputValidator } from '../../core/services/input-validator.interface';
import { ComponentType } from '../models/component-type.model';
import { ComponentTypeService } from '../services/component-type.service';

export class ComponentTypeDetailBaseComponent extends DetailBase<ComponentType> implements OnInit, OnChanges {
  @Input()
  code: string;

  @Input()
  readonly = false;

  constructor(
    api: ComponentTypeService,
    errorHandler: ErrorHandler
  ) {
    super({ api, errorHandler });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  ngOnInit() {
  }

  init() {
    if (this.code === 'new') {
      this.properties = new ComponentType();
      this.properties.calculator = this.properties.calculator || {
        engine: 'fixed',
        formula: ''
      };

      this.properties.frequency = this.properties.frequency || {
        type: 'monthly',
        value: 1
      };
      this.properties.status = 'active';

      return;
    }
    this.get(this.code).subscribe((p) => {
      this.properties.calculator = this.properties.calculator || {
        engine: 'fixed',
        formula: ''
      };

      this.properties.frequency = this.properties.frequency || {
        type: 'manual',
        value: 0
      };
    });

  }
}
