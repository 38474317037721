import { ModelBase } from '../../core/models/model-base.model';
import { Pic } from '../../core/models/pic.model';
import { Link } from '../../core/structures';
import { Service } from './service.model';
import { Address } from '../../core/models';

export class Organization extends ModelBase {
  name: string;
  type: string;
  logo: Pic;
  joinUrl: string;
  navs: Link[];
  services?: Service[];
  email: string;
  phone: string;
  address: Address;
  timezoneOffset: Number
  meta: any;

  constructor(obj?: any) {
    super();

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.code = obj.code;
    this.type = obj.type;
    this.logo = new Pic(obj.logo);
    this.name = obj.name;
    this.timezoneOffset = Number(obj.timezoneOffset);
    this.joinUrl = obj.joinUrl;
    this.status = obj.status;
    this.timeStamp = obj.timeStamp;
    this.email = obj.email;
    this.phone = obj.phone;
    this.meta = obj.meta || {}
    this.address = new Address(obj.address || obj);

    if (obj.navs) {
      this.navs = obj.navs.map((n) => new Link(n));
    }

    this.services = [];

    if (obj.services) {
      obj.services.forEach((item) => {
        this.services.push(new Service(item));
      });
    }
  }
}
