import { AutofillMonitor } from '@angular/cdk/text-field';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { _countGroupLabelsBeforeOption } from '@angular/material';
import * as Chart from 'chart.js';
import { WidgetBaseComponent } from '~/app/open-age/insight/components/widget.base.component';
import { UxService } from '../../../../core/services/ux.service';
import { ReportTypeService } from '../../../../open-age/insight/services/report-type.service';
import { WidgetDataService } from '../../../../open-age/insight/services/widget-data.service';

@Component({
  selector: 'insight-dual-graph-widget',
  templateUrl: './dual-graph-widget.component.html',
  styleUrls: ['./dual-graph-widget.component.css'],
})
export class DualGraphWidgetComponent extends WidgetBaseComponent implements OnInit, OnChanges {
  @Input()
  type: string;
  chart: any;

  data = {
    labels: [],
    datasets: [],
  };
  yAxes = [];

  yAxisLabel = '';
  xAxisLabel = '';

  colorArray = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#FFFF99',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
  ];

  constructor(
    api: WidgetDataService,
    reportTypeService: ReportTypeService,
    uxService: UxService
  ) {
    super(api, reportTypeService, uxService);
    console.log(this);
    this.afterProcessing = () => {
      if (this.reportType.widget && this.reportType.widget.yAxisLabel) {
        this.yAxisLabel = this.reportType.widget.yAxisLabel;
      }
      if (this.reportType.widget && this.reportType.widget.xAxisLabel) {
        this.xAxisLabel = this.reportType.widget.xAxisLabel;
      }
      if (this.reportType.container.code === 'section-1' && !this.params.date) {
        this.bubbleChart();
      } else if (
        this.reportType.container.code === 'firstUSGraphViewTwo' &&
        this.reportType.graph &&
        this.reportType.graph.view === 'horizontal' && !this.params.date
      ) {
        this.horizontalChart();
      } else if (this.reportType.container.code === 'widgets-section-3' && !this.params.date) {
        this.districtChart();
      } else if (this.reportType.code === 'firstUSGraphViewTwo' && !this.params.date) {
        this.hospitalBedsCareChart();
      } else if (this.reportType.container.code === 'firstUSGraphView' && !this.params.date) {
        this.hospitalBedsChart();
      } else if (!this.params.date) {
        this.basicChart();
      }
    };
  }

  basicChart() {
    const dataSets = [];
    const headArray = [];
    const itemHeadArray = [];
    const dataset = {
      dataArray: [],
      colorArray: [],
      borderColorArray: [],
      yAxisID: 'A',
    };
    const first = this.reportType.columns[0].key;
    this.items.forEach((item) => {
      headArray.push(item[first]);
    });
    this.reportType.columns.forEach((column) => {
      if (column.key !== first) {
        itemHeadArray.push(column);
      }
    });
    itemHeadArray.forEach((column) => {
      dataset.dataArray = [];
      dataset.colorArray = [];
      dataset.borderColorArray = [];
      dataset.yAxisID = 'A';
      this.items.forEach((item) => {
        dataset.dataArray.push(item[column.key]);
        if (column.style) {
          if (column.style.background) {
            dataset.colorArray.push(column.style.background);
          }
          if (column.style.border && column.style.border.color) {
            dataset.borderColorArray.push(column.style.border.color);
          }
          this.yAxes = [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: this.yAxisLabel
              }
            },
          ];

          if (this.reportType.container.code === 'firstUSGraphViewTwo') {
            this.yAxes[0].stacked = true;
          }

          if (column.style.yAxisID) {
            if (column.style.yAxisID === 'B') {
              this.yAxes = [
                {
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: itemHeadArray[0].label,
                    fontColor: itemHeadArray[0].style.background,
                    fontSize: 12.5,
                  },
                },
                {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: itemHeadArray[1].label,
                    fontColor: itemHeadArray[1].style.background,
                    fontSize: 12.5,
                  },
                },
              ];
            }
            dataset.yAxisID = column.style.yAxisID;
          }
        }
      });
      dataSets.push({
        label: column.label,
        data: dataset.dataArray,
        yAxisID: dataset.yAxisID,
        backgroundColor: dataset.colorArray,
        fill: false,
        borderColor:
          dataset.borderColorArray && dataset.borderColorArray.length
            ? dataset.borderColorArray.shift()
            : 'rgba(255, 255, 255,1)',
        // borderColor: 'rgba(255, 255, 255,1)',
        borderWidth: 2,
      });
    });
    this.data = {
      labels: headArray,
      datasets: dataSets,
    };
    if (
      !this.isProcessing
    ) {
      let ctx;
      const htmlElement = document.getElementById(this.reportType.code);
      if (htmlElement) {
        ctx = (htmlElement as HTMLCanvasElement).getContext('2d');

        if (this.chart && this.chart.destroy) {
          this.chart.destroy();
        }

        this.chart = new Chart(ctx, {
          type: this.type,
          data: this.data,
          options: {
            legend: {
              // labels: {
              //   boxWidth: 18,
              // },
              // onClick: null,
              // position: "left",
              display: true,
            },
            scales: {
              yAxes: this.yAxes,
              xAxes: [
                {
                  barThickness: 15,
                  stacked:
                    this.reportType.container.code === 'firstUSGraphViewTwo'
                      ? true
                      : false,
                                barPercentage: .6,
                  categoryPercentage: 1.0,
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.xAxisLabel
                  }
                },
              ],
            },
          },
        });
      }
    }
  }

  horizontalChart() {
    const dataSets = [];
    const headArray = [];
    const itemHeadArray = [];
    const dataset = {
      dataArray: [],
      colorArray: [],
      borderColorArray: [],
      yAxisID: 'A',
    };
    const first = this.reportType.columns[0].key;
    this.items.forEach((item) => {
      headArray.push(item[first]);
    });
    this.reportType.columns.forEach((column) => {
      if (column.key !== first) {
        itemHeadArray.push(column);
      }
    });
    itemHeadArray.forEach((column) => {
      dataset.dataArray = [];
      dataset.colorArray = [];
      dataset.borderColorArray = [];
      dataset.yAxisID = 'A';
      this.items.forEach((item) => {
        dataset.dataArray.push(item[column.key]);
        if (column.style) {
          if (column.style.background) {
            dataset.colorArray.push(column.style.background);
          }
          if (column.style.border && column.style.border.color) {
            dataset.borderColorArray.push(column.style.border.color);
          }
          this.yAxes = [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: this.yAxisLabel
              }
            },
          ];

          if (this.reportType.container.code === 'firstUSGraphViewTwo') {
            this.yAxes[0].stacked = true;
          }

          if (column.style.yAxisID) {
            if (column.style.yAxisID === 'B') {
              this.yAxes = [
                {
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: itemHeadArray[0].label,
                    fontColor: itemHeadArray[0].style.background,
                    fontSize: 12.5,
                  },
                },
                {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  ticks: {
                    beginAtZero: true,
                  },
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: itemHeadArray[1].label,
                    fontColor: itemHeadArray[1].style.background,
                    fontSize: 12.5,
                  },
                },
              ];
            }
            dataset.yAxisID = column.style.yAxisID;
          }
        }
      });
      dataSets.push({
        label: column.label,
        data: dataset.dataArray,
        xAxisID: dataset.yAxisID,
        backgroundColor: dataset.colorArray,
        fill: false,
        borderColor:
          dataset.borderColorArray && dataset.borderColorArray.length
            ? dataset.borderColorArray.shift()
            : 'rgba(255, 255, 255,1)',

        borderWidth: 2,
      });
    });
    this.data = {
      labels: headArray,
      datasets: dataSets,
    };
    if (
      !this.isProcessing &&
      this.data.labels.length > 0 &&
      this.data.datasets.length > 0
    ) {
      let ctx;
      const htmlElement = document.getElementById(this.reportType.code);
      if (htmlElement) {
        ctx = (htmlElement as HTMLCanvasElement).getContext('2d');

        if (this.chart && this.chart.destroy) {
          this.chart.destroy();
        }

        this.chart = new Chart(ctx, {
          type: 'horizontalBar',
          data: this.data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            scales: {
              xAxes: this.yAxes,
              yAxes: [
                {
                  // barThickness: 15,
                  stacked:
                    this.reportType.container.code === 'firstUSGraphViewTwo'
                      ? true
                      : false,
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
        });
      }
    }
  }

  bubbleChart() {
    const dataSets = [];
    const headArray = [];
    const dataset = {
      dataArray: [],
      colorArray: [],
      borderColorArray: [],
      yAxisID: 'A',
    };

    let xCordForlarge = 2;
    let yCordForLarge = 6;
    let xCordForLower = 5;
    let yCordForLower = 10;
    let xCordForMedium = 6;
    let yCordForMedium = 20;

    this.items.forEach((item, index) => {
      dataset.dataArray = [];
      dataset.colorArray = [];
      dataset.borderColorArray = [];

      dataset.yAxisID = 'A';

      if (item.percentage < 15) {
        xCordForLower = xCordForLower + 0.25;
        yCordForLower = yCordForLower + 2.5;
      } else if (item.percentage < 30) {
        xCordForLower = xCordForLower + 0.4;
        yCordForLower = yCordForLower - 2.5;
      } else if (item.percentage < 45) {
        xCordForMedium = xCordForMedium + 0.8;
        yCordForMedium = yCordForMedium + 0.7;
      } else if (item.percentage < 60) {
        xCordForMedium = xCordForMedium + 0.8;
        yCordForMedium = yCordForMedium - 2.5;
      } else if (item.percentage < 75) {
        xCordForMedium = xCordForMedium + 0.8;
        yCordForMedium = yCordForMedium - 3;
      } else if (item.percentage < 90) {
        xCordForlarge = xCordForlarge + 1;
        yCordForLarge = yCordForLarge - 3;
      } else {
        xCordForlarge = xCordForlarge + 1;
        yCordForLarge = yCordForLarge + 3;
      }
      dataset.dataArray.push({
        x:
          item.percentage < 75
            ? item.percentage < 30
              ? xCordForLower
              : xCordForMedium
            : xCordForlarge,
        y:
          item.percentage < 75
            ? item.percentage < 30
              ? yCordForLower
              : yCordForMedium
            : yCordForLarge,
        r: item.percentage / 2,
      });
      headArray.push(item.group);

      if (this.reportType.graph && this.reportType.graph.color === 'multi') {
        dataset.colorArray = [this.colorArray[index]];
        dataset.borderColorArray = [this.colorArray[index]];
      }
      if (this.reportType.graph && this.reportType.graph.color === 'single') {
        dataset.colorArray = ['#FF6633'];
        dataset.borderColorArray = ['#FF6633'];
      }

      dataSets.push({
        label: item.group,
        data: dataset.dataArray,
        backgroundColor: dataset.colorArray,
        fill: false,
        borderColor:
          dataset.borderColorArray && dataset.borderColorArray.length
            ? dataset.borderColorArray.shift()
            : 'rgba(255, 255, 255,1)',
        borderWidth: 2,
      });
    });

    this.data = {
      labels: headArray,
      datasets: dataSets,
    };
    if (
      !this.isProcessing &&
      this.data.labels.length > 0 &&
      this.data.datasets.length > 0
    ) {
      let ctx;
      const htmlElement = document.getElementById(this.reportType.code);
      if (htmlElement) {
        ctx = (htmlElement as HTMLCanvasElement).getContext('2d');

        if (this.chart && this.chart.destroy) {
          this.chart.destroy();
        }

        this.chart = new Chart(ctx, {
          type: 'bubble',
          data: this.data,
          options: {
            tooltips: {
              callbacks: {
                label(tooltipItem, data: any) {
                  return ` ${data.labels[tooltipItem.datasetIndex]} ${
                    data.datasets[tooltipItem.datasetIndex].data[0].r
                    } %`;
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'right',
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  // barPercentage: 1,
                  // categoryPercentage: 1,
                  ticks: {
                    beginAtZero: true,
                    display: false, // this will remove only the label
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
        });
      }
    }
  }

  districtChart() {
    const dataSets = [];
    const headArray = [];
    const itemHeadArray = [];
    const dataset = {
      dataArray: [],
      colorArray: [],
      borderColorArray: [],
      yAxisID: 'A',
    };
    const first = this.reportType.columns[0].key;
    this.items.forEach((item) => {
      const isHeadExist = headArray.find((itemHead) => itemHead === item.date);
      if (!isHeadExist) {
        headArray.push(item.date);
      }
      const isExist = itemHeadArray.find(
        (itemHead) => itemHead === item.district
      );
      if (!isExist) {
        itemHeadArray.push(item.district);
      }
    });

    this.yAxes = [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: this.yAxisLabel
        }
      },
    ];

    itemHeadArray.forEach((column) => {
      dataset.dataArray = [];
      dataset.dataArray.length = headArray.length;
      dataset.dataArray.fill(0);
      dataset.colorArray = [];
      dataset.borderColorArray = [];
      dataset.colorArray.length = dataset.dataArray.length;
      dataset.borderColorArray.length = dataset.dataArray.length;
      const randomNumber = Math.floor(Math.random() * 50);
      dataset.colorArray.fill(this.colorArray[randomNumber]);
      dataset.borderColorArray.fill(this.colorArray[randomNumber]);

      dataset.yAxisID = 'A';
      this.items.forEach((item) => {
        if (column === item.district) {
          dataset.dataArray[headArray.indexOf(item.date)] = item.cases;
        }
      });

      const values = this.items.map((value) => { if (column === value.district) { return value.cases; } else { return 0; } });
      const highestCases = Math.max.apply(Math, values);

      dataSets.push({
        label:
          column +
          ' ' +
          highestCases,
        data: dataset.dataArray,
        yAxisID: dataset.yAxisID,
        pointRadius: 0,
        backgroundColor: dataset.colorArray,
        fill: false,
        borderColor:
          dataset.borderColorArray && dataset.borderColorArray.length
            ? dataset.borderColorArray.shift()
            : 'rgba(255, 255, 255,1)',

        borderWidth: 2,
      });
    });
    this.data = {
      labels: headArray,
      datasets: dataSets,
    };
    if (
      !this.isProcessing
    ) {
      let ctx;
      const htmlElement = document.getElementById(this.reportType.code);
      if (htmlElement) {

        ctx = (htmlElement as HTMLCanvasElement).getContext('2d');
        
        if (this.chart && this.chart.destroy) {
          this.chart.destroy();
        }

        this.chart = new Chart(ctx, {
          type: this.type,
          data: this.data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              labels: {
                padding: 14,
                fontStyle: 'oblique',
                boxWidth: 15,
              },
            },
            scales: {
              yAxes: this.yAxes,
              xAxes: [
                {
                  // barThickness: 15,
                  ticks: {
                    display: true, // this will remove only the label
                    maxRotation: 90,
                    minRotation: 80,
                    autoSkip: false,
                    callback: (value, index, values) => {
                      return value;
                    },
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.xAxisLabel
                  },
                  gridLines: {
                    display: false,
                  },
                }
              ],
            },
          },
        });
      }
    }
  }

  hospitalBedsChart() {
    const dataSets = [];
    const headArray = [];
    const itemHeadArray = [];
    const dataset = {
      dataArray: [],
      colorArray: [],
      borderColorArray: [],
      yAxisID: 'A',
    };

    this.items.forEach((item) => {
      const isExist = itemHeadArray.find((itemHead) => itemHead === item.state);
      if (!isExist) {
        itemHeadArray.push(item.state);
      }
    });

    itemHeadArray.forEach((column) => {
      dataset.yAxisID = 'A';
      this.items.forEach((item, index) => {
        if (column === item.state) {
          dataset.dataArray.push(item.beds);
          if (this.reportType.graph && this.reportType.graph.color === 'multi') {
            dataset.colorArray = [this.colorArray[index]];
            dataset.borderColorArray = [this.colorArray[index]];
          }
          if (this.reportType.graph && this.reportType.graph.color === 'single') {
            dataset.colorArray = [];
            dataset.borderColorArray = [];
            this.items.forEach(() => {
              dataset.colorArray.push('#fac834');
              dataset.borderColorArray.push('#fac834');
            });

          }
          // dataset.colorArray.push(this.colorArray[index]);
          // dataset.borderColorArray.push(this.colorArray[index]);
        }
      });
    });

    dataSets.push({
      label: 'state',
      data: dataset.dataArray,
      yAxisID: dataset.yAxisID,
      backgroundColor: dataset.colorArray,
      fill: false,
      borderColor:
        dataset.borderColorArray && dataset.borderColorArray.length
          ? dataset.borderColorArray
          : 'rgba(255, 255, 255,1)',

      borderWidth: 2,
    });
    this.data = {
      labels: itemHeadArray,
      datasets: dataSets,
    };
    if (
      !this.isProcessing &&
      this.data.labels.length > 0 &&
      this.data.datasets.length > 0
    ) {
      let ctx;
      const htmlElement = document.getElementById(this.reportType.code);
      if (htmlElement) {
        ctx = (htmlElement as HTMLCanvasElement).getContext('2d');

        new Chart(ctx, {
          type: this.type,
          data: this.data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'bottom',
            },
            scales: {
              yAxes: [
                {
                  display: true,
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    beginAtZero: true,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.yAxisLabel
                  }
                },
              ],
              xAxes: [
                {
                  // barThickness: 15,
                  ticks: {
                    display: true, // this will remove only the label
                    maxRotation: 90,
                    minRotation: 90,
                    autoSkip: true,
                    callback: (value, index, values) => {
                      return value;
                    },
                  },
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.xAxisLabel
                  }
                },
              ],
            },
          },
        });
      }
    }
  }

  hospitalBedsCareChart() {
    const dataSets = [];
    const headArray = [];
    const itemHeadArray = [];
    const dataset = {
      dataArray: [],
      colorArray: [],
      borderColorArray: [],
      yAxisID: 'A',
    };

    const first = this.reportType.columns[0].key;
    this.items.forEach((item) => {
      headArray.push(item[first]);
    });
    this.reportType.columns.forEach((column) => {
      if (column.key !== first) {
        itemHeadArray.push(column);
      }
    });

    // this.items.forEach((item) => {
    //   const isExist = itemHeadArray.find((itemHead) => itemHead === item.state);
    //   if (!isExist) {
    //     itemHeadArray.push(item.state);
    //   }
    // });
    itemHeadArray.forEach((column) => {
      dataset.dataArray = [];
      dataset.colorArray = [];
      dataset.borderColorArray = [];
      dataset.yAxisID = 'A';
      this.items.forEach((item, index) => {
        dataset.dataArray.push(item[column.key]);
        if (column.style.background) {
          dataset.colorArray.push(column.style.background);
        }
        if (column.style.border && column.style.border.color) {
          dataset.borderColorArray.push(column.style.border.color);
        }
      });
      dataSets.push({
        label: column.label,
        data: dataset.dataArray,
        yAxisID: dataset.yAxisID,
        backgroundColor: dataset.colorArray,
        fill: false,
        borderColor:
          dataset.borderColorArray && dataset.borderColorArray.length
            ? dataset.borderColorArray
            : 'rgba(255, 255, 255,1)',

        borderWidth: 2,
      });
    });

    this.data = {
      labels: headArray,
      datasets: dataSets,
    };
    if (
      !this.isProcessing &&
      this.data.labels.length > 0 &&
      this.data.datasets.length > 0
    ) {
      let ctx;
      const htmlElement = document.getElementById(this.reportType.code);
      if (htmlElement) {
        ctx = (htmlElement as HTMLCanvasElement).getContext('2d');

        if (this.chart && this.chart.destroy) {
          this.chart.destroy();
        }

        this.chart = new Chart(ctx, {
          type: this.type,
          data: this.data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'bottom',
            },
            scales: {
              yAxes: [
                {
                  display: true,
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    beginAtZero: true,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.yAxisLabel
                  }
                },
              ],
              xAxes: [
                {
                  // barThickness: 15,
                  ticks: {
                    display: true, // this will remove only the label
                    maxRotation: 90,
                    minRotation: 80,
                    autoSkip: false,
                    callback: (value, index, values) => {
                      return value;
                    },
                  },
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.xAxisLabel
                  }
                },
              ],
            },
          },
        });
      }
    }
  }
}
