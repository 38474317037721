import { ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { RoleService } from 'src/app/open-age/core/services';
import { Role, User } from 'src/app/open-age/directory/models';

export class LoginOtpBaseComponent implements OnInit, OnChanges {

  @Output()
  success: EventEmitter<Role> = new EventEmitter();

  @Input()
  user: User;

  email = '';
  mobile = '';
  code = '';

  otp = {
    char_1: '',
    char_2: '',
    char_3: '',
    char_4: '',
    char_5: '',
    char_6: '',
  };
  isProcessing = false;

  error: string;

  constructor(
    private auth: RoleService,
    private errorHandler: ErrorHandler
  ) { }

  ngOnChanges(): void {
    if (this.user) {
      this.code = this.user.code;
      this.mobile = this.user.phone;
      this.email = this.user.email;
    }
  }

  ngOnInit() { }

  next() {
    this.isProcessing = true;
    this.auth.sendOtp(this.email, this.mobile, this.code).subscribe((user) => {
      this.isProcessing = false;
      this.user = user;
    }, (error) => {
      this.error = error.message;
      this.isProcessing = false;
      this.errorHandler.handleError(error.message || error);
    });
  }

  cancel() {
    this.user = null;
  }

  confirm() {
    const otp = `${this.otp.char_1}${this.otp.char_2}${this.otp.char_3}${this.otp.char_4}${this.otp.char_5}${this.otp.char_6}`;
    this.isProcessing = true;
    this.auth.verifyOtp(this.user.id, otp).subscribe((role: Role) => {
      this.isProcessing = false;
      this.success.next(role);
    }, (error) => {
      this.error = error.message;
      this.isProcessing = false;
      this.errorHandler.handleError(error.message);
    });
  }
}
