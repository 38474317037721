import { Component, OnInit } from '@angular/core';
import { UxService } from '~/app/core/services';
import { ComponentDetailBaseComponent } from '~/app/open-age/car/components/component-detail-base.component';
import { ComponentService } from '~/app/open-age/car/services';
import { ComponentType } from '../../../open-age/car/models/component-type.model';

@Component({
  selector: 'car-component-editor',
  templateUrl: './component-editor.component.html',
  styleUrls: ['./component-editor.component.css']
})
export class ComponentEditorComponent extends ComponentDetailBaseComponent {

  constructor(
    service: ComponentService,
    uxService: UxService
  ) {
    super(service, uxService);
  }

  setType($event: ComponentType) {
    this.properties.type = $event;
  }

}
