import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent } from '@angular/material';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MemberListBaseComponent } from 'src/app/open-age/gateway/components/member-list.base.component';
import { User } from 'src/app/open-age/gateway/models/user.model';
import { UserService } from 'src/app/open-age/gateway/services';
import { MemberService } from 'src/app/open-age/gateway/services/member.service';
import { UxService } from '../../../core/services/ux.service';

@Component({
  selector: 'gateway-member-list',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent extends MemberListBaseComponent {

  @Input()
  view: 'list' | 'summary' = 'list';

  memberCtrl = new FormControl();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;

  @ViewChild('memberInput') memberInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  filteredUsers: User[];

  isEditing = false;

  constructor(
    service: MemberService,
    uxService: UxService,
    userService: UserService
  ) {
    super(service, uxService);

    this.memberCtrl.valueChanges.pipe(
      debounceTime(300),
      switchMap((value) => userService.search({ name: value }))
    ).subscribe((page) => {
      this.filteredUsers = page.items;
    });
  }

  newMember(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        // this.fruits.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.memberCtrl.setValue(null);
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {

    this.attach(event.option.value);

    this.memberInput.nativeElement.value = '';
    this.memberCtrl.setValue(null);
    this.isEditing = false;
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  // }
}
