import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Task } from 'src/app/open-age/gateway/models/task.model';
import { TaskService } from 'src/app/open-age/gateway/services/task.service';
import { MyTasksBaseComponent } from '~/app/open-age/gateway/components/my-tasks.base.component copy';
import { UxService } from '../../../core/services/ux.service';

@Component({
  selector: 'gateway-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.css']
})
export class MyTasksComponent extends MyTasksBaseComponent {

  newTask: Task = new Task();
  newSelected = false;
  newPlaceholder = '';

  constructor(
    taskService: TaskService,
    private uxService: UxService
  ) {
    super(taskService, uxService);
  }
}
