import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import {
  ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, NgModel, Validators,
} from '@angular/forms';
import { ConfigParam } from 'src/app/open-age/send-it/models';

let identifier = 0;

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.css']
})
export class ConfigFormComponent implements OnInit, OnChanges {

  @Input()
  params: ConfigParam[];

  @Input()
  config: any;

  @Output()
  change: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  identifier = `config-form-${identifier++}`;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    // const configForm = {};
    // this.params.forEach(param => {
    //     configForm[param.name] = new FormControl();
    // })

    // this.form = new FormGroup(configForm);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'].currentValue) {
      const configForm = {};
      changes['params'].currentValue.forEach((param) => {
        const control = new FormControl();
        if (this.config && this.config[param.name]) {
          control.setValue(this.config[param.name], { emitEvent: false });
        }
        configForm[param.name] = control;
      });

      // TODO: add validators
      this.form = new FormGroup(configForm);
    }
  }

  configChanged($event, param: ConfigParam): void {

    this.config[param.name] = $event.target.value;
    this.change.emit(this.config);
  }

  private mapValidators(validators) {
    const formValidators = [];

    if (validators) {
      for (const validation of Object.keys(validators)) {
        if (validation === 'required') {
          formValidators.push(Validators.required);
        } else if (validation === 'min') {
          formValidators.push(Validators.min(validators[validation]));
        }
      }
    }
    return formValidators;
  }
}
