import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { PagerBaseComponent } from '~/app/open-age/core/structures';
import { Compensations } from '../models/compensations.model';
import { CompensationsService } from '../services/compensations.service';

export class CompensationsBaseComponent extends PagerBaseComponent<Compensations> implements OnInit, OnDestroy, OnChanges {

  @Input()
  date: Date = new Date();

  compensation: Compensations;

  isSelected = false;

  constructor(
    service: CompensationsService,
    errorHandler: ErrorHandler
  ) {
    super({
      api: service,
      errorHandler,
      filters: ['user-code', 'date', 'status'],
    });
  }

  ngOnInit() {
    // this.search();
  }

  ngOnChanges() {
    this.search();
  }

  search() {
    this.filters.properties['status'].value = 'all';
    if (this.date) {
      this.filters.properties['date'].value = moment(this.date).startOf('month').toISOString();
    } else {
      this.filters.properties['date'].value = moment().startOf('month').toISOString();
    }
    this.fetch().subscribe();
  }

  ngOnDestroy(): void { }

}
