import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'auth-linkedin-login-button',
  templateUrl: './linkedin-login-button.component.html',
  styleUrls: ['./linkedin-login-button.component.css'],
})
export class LinkedinLoginButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
