import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PagerModel } from '~/app/open-age/core/structures';

import { Project, Release, Sprint, Task, User } from 'src/app/open-age/gateway/models';
import { TaskService } from 'src/app/open-age/gateway/services';
import { UxService } from '~/app/core/services';
import { TimeLogService } from '../../../open-age/ams/services/time-log.service';
import { Entity } from '../../core/models';
import { State } from '../models/state.model';
import { Workflow } from '../models/workflow.model';

export class TaskListBaseComponent extends PagerModel<Task> implements OnInit, OnChanges {

  @Input()
  release: Release;

  @Input()
  sprint: Sprint;

  @Input()
  type: string;

  @Input()
  placeholder = '';

  @Input()
  workflow: Workflow;

  @Input()
  selectedStatus: string | State;

  @Input()
  project: Project;

  @Input()
  parent: Task;

  @Input()
  entity: Entity;

  @Input()
  assignee: string | User;

  @Input()
  isClosed: boolean;

  @Input()
  details: string;

  selectedCode: string;
  selectedSubject: string;

  constructor(
    private api: TaskService,
    public uxService: UxService,
    errorHandler: ErrorHandler
  ) {
    super({
      api,
      errorHandler,
      filters: [
        'project-id', 'release-id', 'sprint-id', 'parent-id',
        'status', 'code', 'subject', 'status-code',
        'workflow-id', 'workflow-code',
        'entity-id', 'entity-type', 'entity-name', 'assignee', 'type', 'isClosed'
      ]
    });
  }

  ngOnInit() {
    // this.refresh();
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    if (!this.details && this.workflow) {
      if (this.workflow.children && this.workflow.children.length) {
        this.details = 'page';
      } else if (this.workflow.meta && this.workflow.meta.details) {
        this.details = this.workflow.meta.details;
      }
    }
    this.filters.reset(false);

    if (this.project) {
      this.filters.set('project-id', this.project.id);
    }

    if (this.isClosed !== undefined) {
      this.filters.set('isClosed', this.isClosed);
    }

    if (this.type) {
      this.filters.set('type', this.type);
    }

    if (this.workflow) {
      this.filters.set('workflow-id', this.workflow.id);
    }

    if (this.selectedStatus) {
      if (typeof this.selectedStatus === 'string') {
        this.filters.set('status-code', this.selectedStatus);
      } else {
        this.filters.set('status-code', this.selectedStatus.code);
      }
    }

    if (this.release) {
      this.filters.set('release-id', this.release.id);
    }

    if (this.sprint) {
      this.filters.set('sprint-id', this.sprint.id);
    }

    if (this.parent !== undefined) {
      if (this.parent === null) {
        this.filters.set('parent-id', 'none');
      } else {
        this.filters.set('parent-id', this.parent.id);
      }
    }

    if (this.selectedCode) {
      this.filters.set('code', this.selectedCode);
    }

    if (this.selectedSubject) {
      this.filters.set('subject', this.selectedSubject);
    }

    if (this.entity) {
      this.filters.set('entity-id', this.entity.id);
      this.filters.set('entity-type', this.entity.type);
      this.filters.set('entity-name', this.entity.name);
    }

    if (this.assignee) {
      this.filters.set('assignee', this.assignee);
    }
    this.fetch();

    // this.api.afterUpdate.subscribe((item: Task) => {
    //   if (this.belongs(item)) {
    //     this.updated.emit(item);
    //   }
    // });
  }

  updateSubject(task: Task, $event) {
    task.isSelected = false;
    const subject = this.uxService.getTextFromEvent($event, this.placeholder);
    if (!subject) {
      return;
    }

    this.api.updateSubject(task, subject).subscribe();
  }

  attach(task: Task) {
    if (this.release) {
      task.release = this.release;
    }

    if (this.project) {
      task.project = this.project;
    }

    this.api.update(task.id, task).subscribe((item) => {
      if (!this.items.find((t) => t.id === item.id)) {
        this.items.push(task);
      }
    });
  }

  detach(task: Task) {
    if (this.release) {
      task.release = null;
    }

    this.api.update(task.id, task).subscribe((item) => {
      if (this.items.find((t) => t.id === item.id)) {
        this.remove(task);
      }
    });
  }

  private belongs(item: Task): boolean {
    if (!item) {
      return false;
    }

    if (this.project && (!item.project || this.project.id !== item.project.id)) {
      return false;
    }

    if (this.type && (!item.type || this.type !== item.type)) {
      return false;
    }

    if (this.workflow && (!item.workflow || item.workflow.id !== this.workflow.id)) {
      return false;
    }

    if (this.selectedStatus && (!item.status || item.status !== this.selectedStatus)) {
      return false;
    }

    if (this.selectedStatus && item.status) {
      if (typeof this.selectedStatus === 'string') {
        if (item.status !== this.selectedStatus) {
          return false;
        }
      } else {
        if (item.status !== this.selectedStatus.code) {
          return false;
        }
      }
    }

    if (this.release && (!item.release || item.release.id !== this.release.id)) {
      return false;
    }

    if (this.sprint && (!item.sprint || item.sprint.id !== this.sprint.id)) {
      return false;
    }

    if (this.parent && (!item.parent || item.parent.id !== this.parent.id)) {
      return false;
    }

    if (this.entity && (!item.entity || item.entity.id !== this.entity.id || item.entity.type !== this.entity.type)) {
      return false;
    }
    if (this.assignee && item.assignedTo) {
      if (typeof this.assignee === 'string') {
        if (item.assignedTo.code !== this.assignee) {
          return false;
        }
      } else {
        if (item.assignedTo.code !== this.assignee.code) {
          return false;
        }
      }
    }

    return true;

  }

  change(item: Task, status) {
    this.api.update(item.id, { status }).subscribe(() => {
      this.uxService.showInfo('task updated successfully');
      this.refresh();
    });
  }
}
