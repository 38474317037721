import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/open-age/send-it/services';
import { UxService } from '../../../core/services/ux.service';
import { InboxBaseComponent } from '../../../open-age/send-it/components/inbox.base.component';

@Component({
  selector: 'send-it-new-notification',
  templateUrl: './new-notification.component.html',
  styleUrls: ['./new-notification.component.css']
})
export class NewNotificationComponent extends InboxBaseComponent implements OnInit {

  constructor(
    messageService: MessageService,
    uxService: UxService) {
    super(messageService, uxService);
  }

  ngOnInit() {
  }

}
