import { formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DateAdapter, MAT_DATE_FORMATS,
  NativeDateAdapter
} from '@angular/material';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { UxService, ValidationService } from '~/app/core/services';
import { Entity, Pic } from '~/app/open-age/core/models';
import { RoleService } from '~/app/open-age/core/services';
import { WizStepBaseComponent } from '~/app/open-age/core/structures/wiz/wiz-step-base.component';
import { Profile, Student, User } from '~/app/open-age/directory/models';
import { Batch } from '~/app/open-age/directory/models/batch.model';
import { Course } from '~/app/open-age/directory/models/course.model';
import { StudentService } from '~/app/open-age/directory/services/student.service';
import { DocsService } from '~/app/open-age/drive/services';

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'dd/MM/yyyy', this.locale);
    } else {
      return date.toDateString();
    }
  }
}
@Component({
  selector: 'directory-student-enrollment',
  templateUrl: './student-enrollment.component.html',
  styleUrls: ['./student-enrollment.component.css'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
  ]
})
export class StudentEnrollmentComponent extends WizStepBaseComponent implements OnInit {

  constructor(
    private api: StudentService,
    public validationService: ValidationService,
    public uxService: UxService,
    private auth: RoleService,
    private docsService: DocsService
  ) {
    super();
  }

  @Input()
  student: Student = new Student();
  @Input()
  usercode: string;
  settings: {};
  @Input()
  readonly: boolean;

  @ViewChild('fileInput')
  fileInput;

  generatedRollNo: any;
  service: any;
  emailError: string;
  mobileError: string;
  image: File;
  email = '';

  public imagePath;
  imgURL: any;
  public message: string;

  ngOnInit() {
    if (!this.student.batch) {
      this.student.batch = new Batch();
    }
    if (!this.student.course) {
      this.student.course = new Course();
    }
    if (!this.student.user) {
      this.student.user = new User();
    }
    if (!this.student.profile) {
      this.student.profile = new Profile();
    }
  }

  disable(disabled: boolean) {
    this.settings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      enableSearchFilter: true,
      disabled,
      maxHeight: 200,
      badgeShowLimit: 1
    };
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.image = files[0];
    this.docsService.upload(this.image).subscribe((doc) => {
      this.student.profile.pic = new Pic({
        url: doc.url,
        thumbnail: doc.thumbnail
      });
    });
  }

  collageSelect(selectedCollage) {
    this.student.institute = selectedCollage;
  }

  batchSelect(selectedBatch) {
    this.student.batch = selectedBatch;
  }

  courseSelect(selectedCourse) {
    this.student.course = selectedCourse;
  }

  validateEmail() {
    this.auth.exists(this.student.email).subscribe((item) => {
      if (item.exists) {
        this.emailError = `${this.student.email} is already Taken`;
        this.student.email = null;
      } else {
        this.emailError = '';
      }
    });
  }

  validateMobile() {
    this.auth.exists(this.student.phone).subscribe((item) => {
      if (item.exists) {
        this.mobileError = `${this.student.phone} is already Taken`;
        this.student.phone = null;
      } else {
        this.mobileError = '';
      }
    });
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) === null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  onPicSelect($event) {
    const files = $event.srcElement.files;
    this.image = files && files.length ? files[0] : null;
    this.docsService.upload(this.image).subscribe((doc) => {
      this.student.profile.pic = new Pic({
        url: doc.url,
        thumbnail: doc.thumbnail
      });
    });
  }

  removePic() {
    this.student.profile.pic = new Pic();
  }

  // createStudent() {
  //   let model = {
  //     id: 0,
  //     batch: {
  //       year: "",
  //       id: this.batch.id
  //     },
  //     personal: {
  //       fatherName: this.student.personal.fatherName,
  //       dob: this.student.user.dob
  //     },
  //     user: {
  //       name: this.student.user.name,
  //       mobile: this.student.user.mobile,
  //       category: this.student.user.category
  //     },
  //     universityRollNo: "",
  //     section: { code: "A" },
  //     address: { firstLine: "undef" },
  //     status: "New",
  //     rollNo: this.batch.lastRollNo + 1
  //   }
  // }

  validate(): any {
    // if (!this.student.user) {
    //   return false;
    // }
    if (!this.student.batch) {
      return 'batch is required';
    }

    if (!this.student.course) {
      return 'course is required';
    }

    if (!this.student.institute) {
      return 'institute is required';
    }

    if (!this.student.email) {
      return 'Email is Required';
    }
    if (!this.student.phone) {
      return 'Phone is Required';
    }

    if (!this.student.profile.lastName) {
      return 'Last Name is required';
    }

    if (!this.student.profile.bloodGroup) {
      return 'Blood Group is required';
    }

    if (!this.student.profile.gender) {
      return 'Gender is required';
    }

    if (!this.student.aadhar) {
      return 'Aadhaar Number is Required';
    }
    if (!this.student.profile.fatherName) {
      return 'Father Name is Required';
    }

    if (this.student.email && this.validationService.isEmailValid(this.student.email)) {
      return 'Incorrect Email';
    }

    if (this.student.phone && this.validationService.isMobileValid(this.student.phone)) {
      return 'Incorrect Phone';
    }

    if (!this.student.profile.firstName) {
      return 'firstname is required';
    }

    if (!this.student.profile || !this.student.profile.dob) {
      return 'DOB is Required';
    }

    return true;
  }
  complete() {
    if (!this.student.status) {
      this.student.status = 'active';
    }

    if (this.readonly) {
      if (this.student && this.student.profile && this.student.profile.pic) {
        return this.api.update(this.student.id, {
          profile: {
            pic: this.student.profile.pic
          }
        });
      }
      return this.api.get(this.student.id);
    }

    if (this.student.id) {
      return this.api.update(this.student.id, new Student(this.student));
    } else {
      const password = moment(this.student.profile.dob).format('d/M/y');
      this.student.password = password;
      const subject = new Subject<Student>();
      this.api.create(this.student).subscribe((s) => {
        this.student.id = s.id;
        this.student.code = s.code;
        subject.next(this.student);
      }, (err) => subject.error(err));

      return subject.asObservable();
    }
  }

  setContext() {
  }
}
