import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { UxService } from '~/app/core/services';
import { Action, DetailBase, Menu } from '~/app/open-age/core/structures';
import { Category, Project, Slide, Sprint, Task, User } from '~/app/open-age/gateway/models';
import { Doc } from '../../drive/models';
import { State } from '../models/state.model';
import { TaskService } from '../services/task.service';

export class IssueDetailBaseComponent extends DetailBase<Task> implements OnInit, OnChanges {

  @Input()
  code: string;

  @Input()
  readonly = false;

  @Input()
  categories: Category[] = [];

  @Input()
  sprints: Sprint[] = [];

  @Input()
  users: User[] = [];

  @Input()
  project: Project;

  @Input()
  sprint: Sprint;

  @Input()
  user: User;

  delay = 0;

  thumbnail: string;

  readyToReceive = false;

  tasks: Task[] = [];
  defects: Task[] = [];
  tests: Task[] = [];
  slides: Slide[] = [];
  attachments: Doc[] = [];

  next: State[] = [];

  constructor(
    public uxService: UxService,
    public issueService: TaskService
  ) {
    super({
      api: issueService
    });
  }

  ngOnInit() {
    // this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  private init() {
    if (!this.properties) {
      const code = this.project ? `${this.code}?project=${this.project.id || this.project.code}` : this.code;
      return this.get(code).subscribe(() => this.init());
    }

    const status: any = this.properties.status;
    this.properties.currentStatus = status ?
      this.properties.workflow.states.find((s) => s.code === status.code) :
      this.properties.workflow.states.find((s) => s.isFirst);

    this.slides = this.issueService.getSlides(this.properties);

    this.attachments = this.issueService.getAttachments(this.properties);
    if (this.attachments.length) {
      this.thumbnail = this.attachments[this.attachments.length - 1].thumbnail;
    }

    const tasks: Task[] = [];
    const tests: Task[] = [];
    const defects: Task[] = [];
    this.properties.children = this.properties.children || [];
    this.properties.children.forEach((issue) => {
      issue.attachments = this.issueService.getAttachments(issue);
      switch (issue.type) {
        case 'bug':
          defects.push(issue);
          break;

        case 'task':
          tasks.push(issue);
          break;

        case 'test':
          tests.push(issue);
          break;
      }
    });

    this.defects = defects;
    this.tasks = tasks;
    this.tests = tests;

    this.calculate();
  }

  copy() {
    const el = document.createElement('textarea');
    el.style.position = 'fixed';
    el.style.left = '0';
    el.style.top = '0';
    el.style.opacity = '0';
    el.value = `${this.properties.subject}
${this.properties.type} #${this.properties.id}`;
    document.body.appendChild(el);
    el.focus();
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  updateStatus(status: State) {
    if (this.properties.currentStatus && this.properties.currentStatus.code === status.code) {
      return;
    }
    this.issueService
      .updateStatus(this.properties, status)
      .subscribe(() => {
        this.properties.currentStatus = this.properties.workflow.states.find((s) => s.code === status.code);
        this.updated.emit(this.properties);
      });
  }

  updateUser(user: User) {
    if (this.properties.user && this.properties.user.id === user.id) {
      return;
    }

    this.issueService
      .updateUser(this.properties, user)
      .subscribe(() => this.updated.emit(this.properties));
  }

  updatePoints(newValue: number) {
    if (this.properties.points === newValue) {
      return;
    }
    this.issueService
      .updatePoints(this.properties, newValue)
      .subscribe(() => this.updated.emit(this.properties));
  }

  updateSprint(sprint: Sprint) {
    if (this.properties.sprint && this.properties.sprint.id === sprint.id) {
      return;
    }

    this.issueService
      .updateSprint(this.properties, sprint)
      .subscribe(() => this.updated.emit(this.properties));
  }

  updateCategory(category: Category) {
    if (this.properties.category && this.properties.category.id === category.id) {
      return;
    }

    this.issueService
      .updateCategory(this.properties, category)
      .subscribe(() => this.updated.emit(this.properties));
  }

  updateSubject($event: any) {
    // this.properties.isEditing = false;

    const subject = this.uxService.getTextFromEvent($event, '');

    if (!subject) {
      return;
    }

    this.issueService
      .updateSubject(this.properties, subject)
      .subscribe(() => this.updated.emit(this.properties));
  }

  onSlidesUpdate(slides: Slide[]) {
    let description = '';

    slides.forEach((item) => {
      description += (description === '' ? '' : '<div>---</div>') + item.description;
    });

    this.updateDescription(description);

  }

  updateDescription($event: any) {

    let newValue = $event;

    if (typeof newValue !== 'string') {
      newValue = this.uxService.getTextFromEvent($event, '');
    }

    if (!newValue) {
      return;
    }

    if (this.properties.description === newValue) {
      return;
    }

    this.issueService
      .updateDescription(this.properties, newValue)
      .subscribe(() => {
        this.slides = this.issueService.getSlides(this.properties);
        this.attachments = this.issueService.getAttachments(this.properties);
        this.updated.emit(this.properties);
      });
  }

  togglePriority() {
    let newValue = '';

    switch (this.properties.priority) {
      case 'low':
        newValue = 'medium';
        break;
      case 'medium':
        newValue = 'high';
        break;
      case 'high':
        newValue = 'low';
        break;
    }
    this.issueService
      .updatePriority(this.properties, newValue)
      .subscribe(() => this.updated.emit(this.properties));
  }

  // taskUpdated($event: Task) {
  //   this.calculate();
  // }

  // taskCreated($event: Task) {
  //   this._addToChildren($event);
  //   this.calculate();
  // }

  // defectCreated($event: Task) {
  //   this._addToChildren($event);
  // }

  // testCreated($event: Task) {
  //   this._addToChildren($event);
  // }

  // private _addToChildren(issue: Task) {

  //   const issues: Task[] = [];
  //   this.properties.children.push(issue);
  //   this.properties.children.forEach((child) => {
  //     if (child.type === issue.type) {
  //       issues.push(child);
  //     }
  //   });

  //   switch (issue.type) {
  //     case 'defect':
  //       this.defects = issues;
  //       break;

  //     case 'task':
  //       this.tasks = issues;
  //       break;

  //     case 'test':
  //       this.tests = issues;
  //       break;
  //   }
  // }

  public calculate() {
    if (this.properties.plan && this.properties.actual && this.properties.plan.finish && this.properties.actual.finish) {
      const adate = moment(this.properties.plan.finish);
      const bdate = moment(this.properties.actual.finish);
      this.delay = bdate.diff(adate, 'minutes');
    }

    let totalPoints = 0;
    let burntPoints = 0;
    const story = this.properties;
    story.children.forEach((task) => {

      if (task.type !== 'task') {
        return;
      }

      totalPoints += task.points;
      if (task.status === 'done') {
        burntPoints += task.points;
      }
    });

    if (story.points === totalPoints && story.burnt === burntPoints) {
      return;
    }

    story.points = totalPoints;
    story.burnt = burntPoints;
    if (story.points !== story.burnt && story.status === 'done') {
      story.status = 'new';
    }

    // this.issueService.update(story.id, story).subscribe(() => this.updated.emit(this.properties));
  }
}
