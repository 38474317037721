import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UxService } from '~/app/core/services';
import { WidgetBaseComponent } from '~/app/open-age/insight/components/widget.base.component';
import { ReportTypeService } from '~/app/open-age/insight/services/report-type.service';
import { WidgetDataService } from '~/app/open-age/insight/services/widget-data.service';

@Component({
  selector: 'insight-grid-filter-widget',
  templateUrl: './grid-filter-widget.component.html',
  styleUrls: ['./grid-filter-widget.component.css']
})

export class GridFilterWidgetComponent extends WidgetBaseComponent {

  @Output()
  raceParam: EventEmitter<{}> = new EventEmitter();

  headArray = [];
  constructor(api: WidgetDataService, reportTypeService: ReportTypeService, uxService: UxService) {
    super(api, reportTypeService, uxService);
  }

  filterAdd(colm) {
    if (this.reportType.code === 'race-grid') {
      const query = {
        value: colm
      };
      this.raceParam.emit(query);
    }
  }

  checkActive(value) {
    if (this.params.race === value) {
      return true;
    } else {
      return false;
    }
  }

}
