import { Course } from '.';
import { ModelBase } from '../../core/models/model-base.model';
import { Organization } from './organization.model';
import { Address } from '../../core/models';

export class Division extends ModelBase {
  name: string;
  isEdit = false;
  courses: Course[];
  address: Address;
  meta: any;
  organization: Organization

  constructor(obj?: any) {
    super();

    if (!obj) {
      return;
    }

    this.isEdit = obj.isEdit;
    this.id = obj.id;
    this.code = obj.code;
    this.name = obj.name;
    this.courses = [];
    if (obj.courses) {
      obj.courses.forEach((course) => {
        this.courses.push(new Course(course));
      });
    }
    this.meta = obj.meta || {}
    this.address = new Address(obj.address || obj);
    this.organization = new Organization(obj.organization || obj);
  }
}
