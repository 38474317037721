import { ErrorHandler, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DetailBase } from '~/app/open-age/core/structures';
import { Job } from '../models';
import { JobService } from '../services/job.service';

export class JobDetailBaseComponent extends DetailBase<Job> implements OnInit, OnChanges {

  @Input()
  code: string;

  @Input()
  readonly = false;

  @Input()
  level: string;

  content: string;

  constructor(
    private api: JobService,
    private errorHandler: ErrorHandler
  ) {
    super({ api, errorHandler });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init(this.code);
  }

  ngOnInit() {
    this.init(this.code);
  }

  init(code) {
    if (code === 'new') {
      this.set(new Job({}));
    } else {
      this.get(code).subscribe(() => {
      });
    }
  }

  run() {
    this.isProcessing = true;
    this.api.run(this.properties.code).subscribe((i) => {
      this.isProcessing = false;
    }, (e) => {
      this.isProcessing = false;
      this.errorHandler.handleError(e);
    });
  }

  dataSourceTypeChanged() {
    this.properties.dataSource.params = [];
    // switch(this.properties.dataSource.type) {
    //   case 'ams':

    // }
  }
}
