import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Employee } from '~/app/open-age/directory/models';
import { ComponentEditorComponent } from '../../car/component-editor/component-editor.component';

@Component({
  selector: 'directory-salary-editor',
  templateUrl: './salary-editor.component.html',
  styleUrls: ['./salary-editor.component.css'],
})
export class SalaryEditorComponent  {

  @ViewChild('editor')
  editor: ComponentEditorComponent;

  @Input()
  employee: Employee;

    userCode: string;

    id: string;

  ngOnInit() {

  }

  validate(): boolean {
    return true;
  }
  complete(): boolean | Observable<any> {
    return true;
  }

  onSave() {
    this.editor.save().subscribe(() => {

    });
  }

}
