import { AfterViewInit, ErrorHandler, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Cropper from 'cropperjs';
import { Entity } from '../../core/models/entity.model';
import { Doc } from '../models';
import { DocsService } from '../services/docs.service';

export class ImageEditorBaseComponent implements OnInit, AfterViewInit {
  @Input()
  title = 'Image Editor';

  @Input()
  body: string;

  @Input()
  file: File;

  @Input()
  template: any;

  @Input()
  cancelable: boolean;

  @Input()
  okLabel = 'Ok';

  @Input()
  cancelLabel = 'Cancel';

  @Input()
  isFixedRatio = false;

  @Input()
  isCropBoxResizable = true;

  @Input()
  ratio = 1;

  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  cropArea = 0.9;

  @Input()
  isEdit = true;

  @Input()
  isEditMandatory = true;

  @Input()
  ratioList: Array<{ name: string, w: number, h: number }> = [];

  @Input()
  tools: string[] = [];

  @Input()
  entity: Entity;

  @Input()
  folder: string;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() done: EventEmitter<Doc> = new EventEmitter();

  visibility: boolean;
  isProcessing: boolean;

  cropper: Cropper;

  options = {
    crop: true,
    zoom: false,
    move: false,
    rotate: false,
    flip: false,
    ratio: false,
    reset: false
  };

  constructor(
    private api: DocsService,
    private errorHandler: ErrorHandler
  ) {
  }

  selectRatio(ratio: { name: string, w: number, h: number }) {
    this.width = ratio.w;
    this.height = ratio.h;
    this.cropper.setAspectRatio(ratio.w / ratio.h);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    const image = document.getElementById('image') as HTMLImageElement;
    image.onload = () => {
      // this.cropper = new Cropper(image);

      this.cropper = new Cropper(image, {
        // dragMode: 'crop', // only in case of 'FALSE' value
        aspectRatio: this.isFixedRatio ? this.ratio : NaN, // for free crop
        autoCrop: true,
        autoCropArea: 0.9,
        restore: false,
        guides: true,
        center: true,
        responsive: true,
        highlight: false,
        cropBoxMovable: true,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: true,
      });
    };
    this.dataUrl(this.file, (err, base64) => {
      image.src = base64;
    });
  }

  toggleEdit(value: boolean) {
  }

  okClicked() {
    this.isProcessing = true;

    const result = this.cropper.getCroppedCanvas({ width: this.width, height: this.height });
    try {
      result.toBlob((blob: any) => {
        blob.name = this.file.name;
        blob.lastModifiedDate = new Date();

        this.api.createByEntity(this.entity, blob, null, this.folder).subscribe((doc) => {
          this.isProcessing = false;
          this.done.emit(doc);
        }, (err) => {
          this.isProcessing = false;
          this.errorHandler.handleError(err.message || err);
        });
      }, 'image/jpeg', 0.8);
    } catch (err) {
      this.isProcessing = false;
      this.errorHandler.handleError(err.message || err);
    }
  }

  cancelClicked() {
    this.cancel.emit();
  }

  close() {
    this.isProcessing = false;
    this.visibility = false;
  }

  open() {
    this.visibility = true;
  }

  private initCropper(image) {
    // const img = new Image();

    //   this.isCropBoxResizable = this.isCropBoxResizable === false ? false : true

    // image.onload = () => {
    //   this.cropper = new Cropper(image, {
    //     dragMode: this.isCropBoxResizable ? 'crop' : 'move', // only in case of 'FALSE' value
    //     aspectRatio: this.isFixedRatio ? this.ratio : NaN, // for free crop
    //     autoCrop: true,
    //     autoCropArea: this.cropArea ? this.cropArea : 0.9,
    //     restore: false,
    //     guides: true,
    //     center: true,
    //     responsive: true,
    //     highlight: false,
    //     cropBoxMovable: true,
    //     cropBoxResizable: this.isCropBoxResizable,
    //     toggleDragModeOnDblclick: true,
    //   });
    // };

    // img.src = image['src'];
  }

  private dataUrl(file: File, callback) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const target: any = e.target;
      callback(null, target.result);
    };
    reader.readAsDataURL(file);
  }

}
