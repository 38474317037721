import { Entity } from '../../core/models/entity.model';
import { ModelBase } from '../../core/models/model-base.model';
import { Folder } from './folder.model';

export class Doc extends ModelBase {
  name: string;
  description: string;
  size: number;
  version: number;
  type: string;
  url: string;
  mimeType: string;
  thumbnail: string;
  timeStamp: Date;
  entity: Entity;
  isEnabled: boolean;
  folder: Folder;
  overwrite: boolean | string;
  timemark: string | number;
  meta: any;
  orderNo: number;
  content: {
    style?: {}
    class: {}
    title?: {
      style?: {}
      class?: {}
      text?: {}
    },
    body?: {
      style?: {}
      class?: {}
      text?: {}
      countrySelector?: boolean
    },
    divs: any
  };

  constructor(obj?: any) {
    super();

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.code = obj.code;
    this.name = obj.name;
    this.description = obj.description;
    this.size = obj.size;
    this.version = obj.version;
    this.type = obj.type;
    this.url = obj.url;
    this.mimeType = obj.mimeType;
    this.timeStamp = obj.timeStamp;
    this.meta = obj.meta;
    this.isEnabled = obj.isEnabled;
    this.orderNo = obj.orderNo || 0

    if (obj.entity) {
      this.entity = new Entity(obj.entity);
    }

    if (obj.folder) {
      this.folder = new Folder(obj.folder);
    }

    if (obj.content) {
      this.content = JSON.parse(obj.content);
    }
  }
}
