import { ModelBase } from '../../core/models/model-base.model';
export class DeviceType extends ModelBase {
  manufacturer: string;
  model: string;
  picData: string;
  picUrl: string;
  port: string;

  constructor(obj?: any) {
    super();

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.code = obj.code;
    this.manufacturer = obj.manufacturer;
    this.picData = obj.picData;
    this.picUrl = obj.picUrl;
    this.port = obj.port;
  }
}
