import { UxService } from '../../../core/services/ux.service';
import { TelepainApiBase } from './telepain-api.base';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleService } from '../../core/services/role.service';
import { CuresReport } from '../models/cures-report'
import { IApi } from '../../core/services';

@Injectable({
  providedIn: 'root'
})
export class CuresReportService extends TelepainApiBase<CuresReport>{

  details: IApi<any>;

  constructor(
    http: HttpClient,
    roleService: RoleService,
    uxService: UxService) {
    super('patient-cures-report', http, roleService, uxService);
    this.details = new TelepainApiBase<any>(`patient-cures-report/details`, http, roleService, uxService);
  }
}
