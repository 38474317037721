import { HttpClient } from '@angular/common/http';
import { UxService } from '~/app/core/services';
import { GenericApi } from '~/app/open-age/core/services';
import { RoleService } from '../../core/services';

export class AmsApi<TModel> extends GenericApi<TModel> {
  constructor(
    key: string,
    http: HttpClient,
    roleService: RoleService,
    errorHandler: UxService
  ) {

    super(
      http,
      'ams', {
      collection: key,
      headers: [{
        key: 'authorization',
        value: () => roleService.currentRole().token
      }, {
        key: 'x-tenant-code',
        value: () => roleService.currentTenant().code
      }],
      errorHandler
    });
  }
}
