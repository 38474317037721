import { Department, Section, User } from '.';
import { Address } from '../../core/models';
import { ModelBase } from '../../core/models/model-base.model';
import { Batch } from './batch.model';
import { Course } from './course.model';
import { Division } from './division.model';
import { Profile } from './profile.model';

export class Student extends ModelBase {
  profile: Profile;
  user: User;
  email: string;
  phone: string;
  batch: Batch;
  course: Course;
  institute: Division;
  aadhar: string;
  type: string;
  section: Section;
  // spot: Spot;
  // busPass: BusPass;
  documents: Document;
  division: Department;
  extraInfoString: string;
  extraInfo: any;
  address: Address;
  password: string;

  constructor(obj?: any) {
    super(obj);

    if (!obj) {
      return;
    }
    this.id = obj.id;
    this.code = obj.code;
    this.email = obj.email;
    this.phone = obj.phone;
    this.address = new Address(obj.address || obj);
    this.profile = new Profile(obj.profile || obj);
    this.section = new Section(obj.section || obj);
    this.batch = new Batch(obj.batch || obj);
    this.course = new Course(obj.course || obj);
    this.institute = new Division(obj.institute || obj);
    this.profile = new Profile(obj.profile || obj);
    this.aadhar = obj.aadhar;
  }
}
