import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ReleaseState } from '~/app/open-age/gateway/models/release-state.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'gateway-workflow-state-summary',
  templateUrl: './workflow-state-summary.component.html',
  styleUrls: ['./workflow-state-summary.component.css']
})
export class WorkflowStateSummaryComponent implements OnInit, OnChanges {

  @Input()
  workflowState: ReleaseState;

  @Output()
  selected: EventEmitter<ReleaseState> = new EventEmitter();

  status: string;

  took: string;
  completedAt: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.calculate();
  }

  calculate() {
    if (!this.workflowState.actual.start) {
      this.status = 'planned';
    } else if (!this.workflowState.actual.finish) {
      this.status = 'wip';
    } else {
      this.status = 'done';
    }
  }

  select() {
    this.workflowState.isSelected = true;
    this.selected.emit(this.workflowState);
  }

}
