import { ModelBase } from '../../core/models/model-base.model';
import { Points } from './points.model';

export class User extends ModelBase {
  points: Points;

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }
    this.points = new Points(obj.points);
  }
}
