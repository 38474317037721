import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PagerModel } from '~/app/open-age/core/structures';

import { Project, Release, Sprint, Task } from 'src/app/open-age/gateway/models';
import { TaskService } from 'src/app/open-age/gateway/services';
import { Entity } from '../../core/models';

export class MyTasksBaseComponent extends PagerModel<Task> implements OnInit, OnChanges {

  @Input()
  release: Release;

  @Input()
  sprint: Sprint;

  @Input()
  project: Project;

  @Input()
  parent: Task;

  @Input()
  entity: Entity;

  @Input()
  selectedStatus: string;

  @Input()
  assignee: string;

  selectedCode: string;
  selectedSubject: string;

  constructor(
    private api: TaskService,
    errorHandler: ErrorHandler
  ) {
    super({
      api,
      errorHandler,
      filters: ['projectId', 'releaseId', 'sprintId', 'parentId',
        'status', 'code', 'subject',
        'entity-id', 'entity-type', 'entity-name', 'assignee']
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    this.filters.reset(false);

    if (this.project) {
      this.filters.set('projectId', this.project.id);
    }

    if (this.release) {
      this.filters.set('releaseId', this.release.id);
    }

    if (this.sprint) {
      this.filters.set('sprintId', this.sprint.id);
    }

    if (this.parent) {
      this.filters.set('parentId', this.parent.id);
    }

    if (this.selectedStatus) {
      this.filters.set('status', this.selectedStatus);
    }

    if (this.selectedCode) {
      this.filters.set('code', this.selectedCode);
    }

    if (this.selectedSubject) {
      this.filters.set('subject', this.selectedSubject);
    }

    if (this.entity) {
      this.filters.set('entity-id', this.entity.id);
      this.filters.set('entity-type', this.entity.type);
      this.filters.set('entity-name', this.entity.name);
    }

    if (this.assignee) {
      this.filters.set('assignee', this.assignee);
    } else {
      this.filters.set('assignee', 'my');
    }

    this.fetch();
  }

  attach(task: Task) {
    if (this.release) {
      task.release = this.release;
    }

    if (this.project) {
      task.project = this.project;
    }

    this.api.update(task.id, task).subscribe((item) => {
      if (!this.items.find((t) => t.id === item.id)) {
        this.items.push(task);
      }
    });
  }

  detach(task: Task) {
    if (this.release) {
      task.release = null;
    }

    this.api.update(task.id, task).subscribe((item) => {
      if (this.items.find((t) => t.id === item.id)) {
        this.remove(task);
      }
    });
  }

  changeStatus(status: string, task: Task) {
    this.api.update(task.id, { status }).subscribe((item) => {
      this.fetch();
    });
  }
}
