import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Message } from 'src/app/open-age/send-it/models';
import { MessageService } from 'src/app/open-age/send-it/services';
import { ConversationService } from '~/app/open-age/send-it/services';
import { Entity } from '../../core/models';
import { Conversation } from '../models/conversation.model';

export class NewMessageBaseComponent implements OnInit {

  @Input()
  conversation: Conversation;

  @Input()
  entity: Entity;

  @Input()
  toolbar: any[] = [
    ['bold', 'italic', 'underline'],        // toggled buttons
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: new Array<any>() }]
  ];

  @Output()
  sent: EventEmitter<Message> = new EventEmitter();

  message: Message = new Message();

  content: string;

  errors: string[] = [];

  isProcessing = false;

  constructor(
    private conversationService: ConversationService,
    private service: MessageService
  ) {
  }

  ngOnInit() {
  }

  setSubject(subject: string) {
    this.message.subject = subject;
  }

  setContent($event: string) {
    this.message.body = $event;
  }

  send() {
    if (!this.message.subject) {
      this.errors = [];
      this.errors.push('message cannot be empty');
      return;
    }

    this.isProcessing = true;

    if (this.conversation) {
      this.createMessage();
    } else if (this.entity) {
      this.conversationService.getByEntity(this.entity).subscribe((c) => {
        this.conversation = c;
        this.createMessage();
      });
    }
  }
  createMessage() {
    this.message.conversation = this.conversation;
    this.service.create(this.message).subscribe((message) => {
      this.isProcessing = false;
      this.sent.emit(message);
      this.message = new Message();
    }, (err) => {
      this.isProcessing = false;
      this.errors = [];
      this.errors.push(err);
    });
  }
}
