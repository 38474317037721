import { ModelBase } from '../../core/models/model-base.model';
import { User } from './user.model';

export class Log extends ModelBase {

  message: string;
  level: string;
  user: User;
  meta: {} = {};
  error: any;
  location: string;
  timeStamp: Date

  constructor(obj?: any) {
    super();

    if (!obj) { return; }
    this.id = obj.id;
    this.timeStamp = obj.timeStamp;
    this.message = obj.message;
    this.meta = obj.meta;
    this.level = obj.level;
    this.error = obj.error;
    this.location = obj.location;
    if (obj.user) {
      this.user = new User(obj.user);
    }
    this.timeStamp = new Date(obj.timeStamp)
  }
}
