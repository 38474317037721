import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TemplateListBaseComponent } from 'src/app/open-age/send-it/components/template-list.base.components';
import { TemplateService, UserService } from 'src/app/open-age/send-it/services';
import { UxService } from '../../../core/services/ux.service';

@Component({
  selector: 'send-it-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.css']
})
export class TemplateListComponent extends TemplateListBaseComponent {

  constructor(
    private templateService: TemplateService,
    private uxService: UxService,
    private dialog: MatDialog
  ) {
    super(templateService, uxService);
  }
}
