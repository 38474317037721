import { Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/open-age/gateway/models/project.model';
import { Release } from 'src/app/open-age/gateway/models/release.model';

import { Member } from 'src/app/open-age/gateway/models/member.model';
import { User } from 'src/app/open-age/gateway/models/user.model';
import { MemberService } from 'src/app/open-age/gateway/services/member.service';
import { PagerModel } from '~/app/open-age/core/structures';
import { Role, Sprint, Task } from '../models';
import { ReleaseState } from '../models/release-state.model';

export class MemberListBaseComponent extends PagerModel<Member> implements OnChanges {

  @Input()
  release: Release;

  @Input()
  project: Project;

  @Input()
  sprint: Sprint;

  @Input()
  role: Role;

  @Input()
  task: Task;

  @Input()
  member: Member;

  constructor(
    private api: MemberService,
    errorHandler: ErrorHandler
  ) {
    super({
      api, errorHandler,
      filters: ['project-id', 'release-id', 'state-id', 'task-id']
    });
  }

  // ngOnInit() {

  //   let role: Role;
  //   if (this.role) {
  //     role = typeof this.role === 'string' ? new Role({ code: this.role }) : this.role;
  //   }

  //   if (this.sprint && this.sprint.members && this.sprint.members.length) {
  //     if (role) {
  //       this.items = this.sprint.members.filter((i) => i.role.code === role.code);
  //     } else {
  //       this.items = this.sprint.members;
  //     }
  //   } else if (this.release && this.release.members && this.release.members.length) {
  //     if (role) {
  //       this.items = this.release.members.filter((i) => i.role.code === role.code);
  //     } else {
  //       this.items = this.release.members;
  //     }
  //   } else if (this.project && this.project.members && this.project.members.length) {
  //     if (this.role) {
  //       this.items = this.project.members.filter((i) => i.role.code === role.code);
  //     } else {
  //       this.items = this.project.members;
  //     }
  //   } else {
  //     this.refresh();
  //   }
  // }

  ngOnChanges() {
    let role: Role;
    if (this.role) {
      role = typeof this.role === 'string' ? new Role({ code: this.role }) : this.role;
    }

    if (this.task && this.task.members && this.task.members.length) {
      if (role) {
        this.items = this.task.members.filter((i) => i.role.code === role.code);
      } else {
        this.items = this.task.members;
      }
    } else if (this.sprint && this.sprint.members && this.sprint.members.length) {
      if (role) {
        this.items = this.sprint.members.filter((i) => i.role.code === role.code);
      } else {
        this.items = this.sprint.members;
      }
    } else if (this.release && this.release.members && this.release.members.length) {
      if (role) {
        this.items = this.release.members.filter((i) => i.role.code === role.code);
      } else {
        this.items = this.release.members;
      }
    } else if (this.project && this.project.members && this.project.members.length) {
      if (role) {
        this.items = this.project.members.filter((i) => i.role.code === role.code);
      } else {
        this.items = this.project.members;
      }
    } else {
      this.refresh();
    }
  }

  refresh() {
    if (this.project) {
      this.filters.properties['project-id'].value = this.project.id;
    }

    if (this.release) {
      this.filters.properties['release-id'].value = this.release.id;
    }

    if (this.sprint) {
      this.filters.properties['state-id'].value = this.sprint.id;
    }

    if (this.task) {
      this.filters.properties['task-id'].value = this.task.id;
    }

    let role: Role;
    if (this.role) {
      role = typeof this.role === 'string' ? new Role({ code: this.role }) : this.role;
    }
    if (role) {
      this.filters.properties['role-code'].value = role.code;
    }

    this.fetch();
  }

  attach(user: User) {
    this.isProcessing = true;
    const newMember = new Member();

    if (this.role) {
      newMember.role = typeof this.role === 'string' ? new Role({ code: this.role }) : this.role;
    }
    newMember.user = user;
    if (this.release) {
      newMember.release = this.release;
    }

    if (this.project) {
      newMember.project = this.project;
    }

    // if (this.state) {
    //   newMember.state = this.state;
    // }

    this.api.create(newMember).subscribe((item) => {
      if (!this.items.find((t) => t.id === item.id)) {
        this.items.push(item);
      }
      this.isProcessing = false;
    });
  }

  detach(member: Member) {
    this.isProcessing = true;
    this.api.remove(member.id).subscribe(() => {
      this.items = this.items.filter((i) => i.id !== member.id);
      this.isProcessing = false;
    });
  }
}
