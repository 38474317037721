import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { DetailBase, PagerBaseComponent } from '~/app/open-age/core/structures';
import { Compensations } from '../models/compensations.model';
import { CompensationsService } from '../services/compensations.service';

export class CompensationsDetailBaseComponent extends DetailBase<Compensations> implements OnInit, OnDestroy {

  @Input()
  code: string;

  @Input()
  date = new Date();

  compensation: Compensations;

  netSalaryValue: number;

  deductionValue: number;

  earningValue: number;

  constructor(
    service: CompensationsService,
    errorHandler: ErrorHandler
  ) {
    super({
      api: service,
      errorHandler,
    });
  }

  ngOnInit() {
    this.search();
  }

  ngOnChanges() {
    this.search();
  }

  search() {
    let dateFilter;
    dateFilter = moment(this.date).format('YYYY-MM');
    if (this.code) {
      this.get(`${dateFilter}?${this.code}`).subscribe((p) => {
        this.init(p);
      }, (error) => {
        this.compensation = null;
      });
    }
  }

  init(data) {
    this.compensation = new Compensations();
    this.compensation = data;
    this.getEarning();
    this.getDeductions();
    this.getNetSalary();
  }

  getEarning() {
    let amount = 0;
    if (this.compensation.earnings.length) {
      this.compensation.earnings.forEach((data) => {
        if (data.amount) {
          amount = data.amount + amount;
        }
      });
    }
    return this.earningValue = amount;
  }

  getDeductions() {
    let amount = 0;
    if (this.compensation.deductions.length) {
      this.compensation.deductions.forEach((data) => {
        if (data.amount) {
          amount = data.amount + amount;
        }
      });
    }
    return this.deductionValue = amount;
  }

  getNetSalary() {
    return this.netSalaryValue = this.getEarning() - this.getDeductions();
  }

  ngOnDestroy(): void {
  }

}
