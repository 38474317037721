import { Component} from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { ReportListBaseComponent } from 'src/app/open-age/insight/components/report-list.base.component';
import { ReportService } from 'src/app/open-age/insight/services/report.service';

@Component({
  selector: 'insight-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent extends ReportListBaseComponent {
  constructor(
    api: ReportService,
    errorHandler: UxService
  ) {
    super(api, errorHandler);
  }
}
