import { ErrorHandler, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { PagerBaseComponent } from '~/app/open-age/core/structures';
import { Component } from '../models/component.model';
import { ComponentService } from '../services/component.service';

export class ComponentListBaseComponent extends PagerBaseComponent<Component> implements OnInit, OnChanges, OnDestroy {

  @Input()
  readonly = false;

  @Input()
  sort = 'code';

  @Input()
  typeCodeFilter = '';

  @Input()
  userCodeFilter = '';

  @Input()
  statusFilter = '';

  @Input()
  tempFilter = false;

  @Input()
  isRefresh = false;

  constructor(
    service: ComponentService,
    errorHandler: ErrorHandler
  ) {
    super({
      api: service,
      errorHandler,
      filters: ['type-code', 'user-code', 'status'],
    });
  }

  ngOnInit() {
    // this.search();
  }

  ngOnChanges(): void {
    this.search();
  }

  search() {

    this.filters.properties['user-code'].value = this.userCodeFilter;
    this.filters.properties['type-code'].value = this.typeCodeFilter;
    this.filters.properties['status'].value = this.statusFilter;
    this.currentPageNo = 1;

    this.fetch();
  }

  ngOnDestroy(): void {
  }

}
