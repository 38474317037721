import { Pic } from '~/app/open-age/core/models/pic.model';
import { User } from '~/app/open-age/directory/models/user.model';
import { Link } from '../../core/structures';
import { Service } from './service.model';

export class Tenant {
  id: string;
  code: string;
  name: string;
  host: string;
  meta: any;
  logo: Pic;
  joinUrl: string;
  homeUrl: string;
  owner: User;
  navs: Link[];
  services: Service[];
  level: string;
  timeStamp: Date;
  styles: string;

  constructor(obj?: any) {

    if (!obj) {
      return;
    }

    this.id = obj.id;
    this.code = obj.code;
    this.host = obj.host;
    this.logo = new Pic(obj.logo);
    this.name = obj.name;
    this.joinUrl = obj.joinUrl;
    this.homeUrl = obj.homeUrl;
    this.level = obj.level;
    this.timeStamp = obj.timeStamp || new Date();
    this.meta = obj.meta || {}

    if (obj.owner) {
      this.owner = new User(obj.owner);
    }

    if (obj.navs) {
      console.log('teant navs');
      this.navs = obj.navs.map((n) => new Link(n));
    }

    if (obj.services) {
      this.services = obj.services.map((s) => new Service(s));
    }

    if (obj.styles) {
      this.styles = obj.styles;
    }
  }
}
