import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import { TaskListBaseComponent } from 'src/app/open-age/gateway/components/task-list.base.component';
import { Task } from 'src/app/open-age/gateway/models/task.model';
import { TaskService } from 'src/app/open-age/gateway/services/task.service';
import { UxService } from '../../../core/services/ux.service';

@Component({
  selector: 'gateway-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent extends TaskListBaseComponent {

  panelOpenState: string;
  @Input()
  view: 'table' | 'cards' = 'cards';

  newTask: Task = new Task();
  newSelected = false;

  columns = ['details', 'actions'];

  constructor(
    taskService: TaskService,
    uxService: UxService
  ) {
    super(taskService, uxService, uxService);
  }
  getDelay(item) {
    const adate = moment(item.plan.finish);
    const bdate = moment(item.actual.finish);
    const days = bdate.diff(adate, 'days');
    if (days > 0) {
      return days;
    } else {
      return '0';
    }
  }
  addNewTask($event) {
    this.newSelected = false;
    const subject = this.uxService.getTextFromEvent($event, this.placeholder);

    if (!subject) {
      return;
    }
    const issue = new Task();
    issue.subject = subject;
    issue.type = this.type;
    issue.workflow = this.workflow;
    issue.parent = this.parent;
    issue.project = this.project;
    issue.release = this.release;
    issue.sprint = this.sprint;
    issue.entity = this.entity;

    return this.create(issue).subscribe((t) => {
      this.uxService.setTextFromEvent($event, this.placeholder);
    });
  }
}
