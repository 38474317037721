import { PracticeLocation } from '~/app/open-age/telepain/models/practice-location';
import { Practice } from './practice';
import { ModelBase } from '../../core/models/model-base.model';
import { Profile } from './profile';

export class Practitioner extends ModelBase {

    email: string;
    phone: string;
    prefix: string;
    profile: Profile;
    practice: Practice;
    practiceLocation: PracticeLocation;

    constructor(obj?: any) {
        super();

        if (!obj) {
            return;
        }

        this.id = obj.id;
        this.email = obj.email;
        this.phone = obj.phone;
        this.prefix = obj.prefix;
        this.profile = new Profile(obj.profile || obj);
        this.practice = new Practice(obj.practitioner);
        this.practiceLocation = new PracticeLocation(obj.practiceLocation);
    }
}
