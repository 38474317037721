import { ErrorHandler, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { UxService } from '~/app/core/services/ux.service';
import { Folder } from '~/app/open-age/drive/models';
import { FolderService } from '../services';

export class NewFolderBaseComponent implements OnInit, OnChanges {

  newFolder: Folder;

  isProcessing: boolean;

  @Output()
  created: EventEmitter<Folder> = new EventEmitter<Folder>();

  constructor(
    private service: FolderService,
    private uxService: ErrorHandler
  ) {
    this.newFolder = new Folder();
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  create(folder: Folder) {
    if (!folder.name) { return; }

    if (folder.name.toLowerCase() === 'root') {
      this.uxService.handleError(`You can't add Root Folder`);
    }

    folder.status = 'active';

    this.isProcessing = true;

    this.service.create(folder).subscribe((data) => {
      this.isProcessing = false;
      this.newFolder = new Folder();
      this.created.emit(data);
    }, (err) => {
      this.isProcessing = false;
      this.uxService.handleError(err.message || err);
    });
  }

}
