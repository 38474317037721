import { ModelBase } from '../../core/models/model-base.model';
import { Amount } from './amount.model';
import { User } from './user.model';

export class Compensations extends ModelBase {

    date: Date;
    status: string;
    period: string;
    endDate: Date;
    earnings: Amount[];
    deductions: Amount[];
    user: User;
    isSelected: boolean;

    constructor(obj?: any) {
        super();
        if (!obj) {
            return;
        }
        this.id = obj.id;
        this.code = obj.code;
        this.date = obj.date;
        this.endDate = obj.endDate;
        this.status = obj.status;
        if (obj.earnings) {
            obj.earnings.forEach((item) => {
                this.earnings.push(new Amount(item));
            });
        }
        if (obj.deductions) {
            obj.deductions.forEach((item) => {
                this.deductions.push(new Amount(item));
            });
        }
    }

}
