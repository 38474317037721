import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatIconModule,
  MatMenuModule
} from '@angular/material';
import { AvatarModule } from 'ngx-avatar';
import { OaDriveModule } from '../drive/oa-drive.module';
import { AvatarComponent } from './avatar/avatar.component';
import { CalendarDetailComponent } from './calendar/calendar-detail.component';
import { QuillEditorComponent } from './quill-editor/quill-editor.component';
import { SlidesComponent } from './slides/slides.component';

const dialogs = [];
const components = [
  SlidesComponent,
  QuillEditorComponent,
  CalendarDetailComponent,
  AvatarComponent
];
const thirdPartyModules = [
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  AvatarModule

];
const services = [];
const guards = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    OaDriveModule,
    ...thirdPartyModules
  ],
  declarations: [...components, ...dialogs, ...pipes],
  exports: [...components, ...dialogs, ...pipes],
  providers: [...services, ...guards],
  entryComponents: [...dialogs]
})
export class OaCommonModule { }
