import { Component, Input, OnInit } from '@angular/core';
import { Organization } from '../../../open-age/directory/models/organization.model';

@Component({
  selector: 'directory-organization-summary',
  templateUrl: './organization-summary.component.html',
  styleUrls: ['./organization-summary.component.css']
})
export class OrganizationSummaryComponent implements OnInit {

  @Input()
  organization: Organization;

  constructor() { }

  ngOnInit() {
  }

}
