import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/open-age/send-it/services';
import { UxService } from '../../../core/services/ux.service';
import { RoleService } from '../../../open-age/core/services/role.service';
import { InboxBaseComponent } from '../../../open-age/send-it/components/inbox.base.component';

@Component({
  selector: 'send-it-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent extends InboxBaseComponent {

  constructor(
    service: MessageService,
    uxService: UxService
  ) {
    super(service, uxService);
  }
}
