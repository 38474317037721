import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-clocked-selector',
  templateUrl: './clocked-selector.component.html',
  styleUrls: ['./clocked-selector.component.css']
})
export class ClockedSelectorComponent implements OnInit {

  @Input()
  selected: {};

  @Input()
  reset = false;

  @Output()
  change: EventEmitter<string[]> = new EventEmitter();

  data = [];
  settings = {};
  selectedItem: string[] = [];
  selectedClocked: string[] = [];

  constructor() { }

  ngOnInit() {
    this.data.push({
      id: '1',
      itemName: 'Short',
      code: 'short'
    });
    this.data.push({
      id: '2',
      itemName: 'Extra',
      code: 'extra'
    });
  }

  ngOnChanges() {
    if (this.reset === true) {
      this.selected = null;
      this.reset = false;
      this.selectedItem = [];
      this.selectedClocked = [];
    }
    this.settings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      enableSearchFilter: true,
      maxHeight: 200,
      badgeShowLimit: 1
    };
  }

  onSelect($event) {
    if (this.selectedItem === null) {
      this.selectedItem = [];
    }
    this.selectedClocked.push($event.code);
    this.selectedItem.push(`'` + $event.code + `'`);
    this.change.emit(this.selectedItem);
  }

  onDeSelect($event) {
    this.selectedItem = [];
    this.selectedClocked = this.selectedClocked.filter((item) => item !== $event.code);
    this.selectedClocked.forEach((item) => [
      this.selectedItem.push(`'` + item + `'`)
    ]);
    if (this.selectedItem.length < 1) {
      this.selectedItem = null;
    }
    this.change.emit(this.selectedItem);
  }

  onSelectAll(items: any) {
    this.selectedItem = [];
    items.forEach((vendor) => {
      this.selectedItem.push(vendor.code);
    });
    this.change.emit(this.selectedItem);
  }
  onDeSelectAll(items: any) {
    this.selectedItem = null;
    this.change.emit(this.selectedItem);
  }

}
