import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UxService } from 'src/app/core/services/ux.service';
import { TemplateDetailBaseComponent } from 'src/app/open-age/send-it/components/template-detail.base.component';
import { TemplateConfig } from 'src/app/open-age/send-it/models';
import { TemplateDataSource } from 'src/app/open-age/send-it/models/template-data-source.model';
import { DocumentService } from 'src/app/open-age/send-it/services';
import { TemplateService } from '../../../open-age/send-it/services/template.service';

@Component({
  selector: 'send-it-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.css']
})
export class TemplateDetailsComponent extends TemplateDetailBaseComponent {

  @Input()
  view = 'editor';

  size: string;

  meta: string;

  preview: {
    data?: any,
    subject?: string,
    body?: string
  } = {};
  constructor(
    private documentService: DocumentService,
    service: TemplateService,
    uxService: UxService
  ) { super(service, uxService); }

  showPreview() {
    this.isProcessing = true;
    this.documentService.previewByCode(this.properties.code, {
      dataSource: this.properties.dataSource
    }).subscribe((doc) => {
      this.preview = {
        subject: doc.name,
        body: doc.content
      };
      this.view = 'preview';
      this.isProcessing = false;
    });
  }

  addConfig() {
    this.properties.config = new TemplateConfig({});
  }

  addDataSource() {
    this.properties.dataSource = new TemplateDataSource({});
  }

  onMetaSet($event) {
    this.properties.dataSource.meta = $event;
  }
}
