import { Component, OnInit, ErrorHandler, Input } from '@angular/core';
import { OrganizationListBaseComponent } from '~/app/open-age/directory/components/organization-list.base.component';
import { OrganizationService } from '~/app/open-age/directory/services';
import { ConfirmDialogComponent } from '~/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { UxService } from '~/app/core/services';

@Component({
  selector: 'directory-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.css']
})
export class OrganizationListComponent extends OrganizationListBaseComponent implements OnInit {

  @Input()
  noEmail = false;
  showInactive: boolean = false;
  tableColumns = ['no', 'name', 'location', 'email', 'phone', 'enable']

  constructor(
    public dialog: MatDialog,
    private uxService: UxService,
    api: OrganizationService) {
    super(api, uxService)
  }

  ngOnInit() {
    if (this.noEmail) {
      this.tableColumns = ['no', 'name', 'location', 'phone', 'enable']
    }
  }

  onRemove(item): void {
    this.uxService.onConfirm().subscribe(() => {
      this.remove(item);
      this.uxService.showInfo('Deleted');
    });
  }
  openDialog(item): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.remove(item);
        return this.uxService.showInfo('Deleted sucessfully');
      }
    });
  }

  changeStatus(event) {
    if (this.showInactive) {
      this.status = "inactive"
    } else {
      this.status = "active"
    }
    this.refresh()
  }

}
