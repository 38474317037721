import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
declare var require: any;

import * as Quill from 'quill';

@Component({
  selector: 'oa-content-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: [
    './quill-editor.component.css',
    '../../../../../node_modules/quill/dist/quill.core.css',
    '../../../../../node_modules/quill/dist/quill.snow.css',
    '../../../../../node_modules/quill/dist/quill.bubble.css'
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QuillEditorComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class QuillEditorComponent implements AfterViewInit, ControlValueAccessor, OnChanges {

  @Input()
  content: string;

  @Input()
  readonly = false;

  @Input()
  placeholder: string;

  @Input()
  toolbar: any[] = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],               // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
    [{ direction: 'rtl' }],                         // text direction

    [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: new Array<any>() }, { background: new Array<any>() }],          // dropdown with defaults from theme
    [{ font: new Array<any>() }],
    [{ align: new Array<any>() }],

    ['clean'],                                         // remove formatting button

    ['link', 'image', 'video']                         // link and image, video
  ];

  @Input()
  options: any;

  quillEditor: any;
  editorElem: HTMLElement;

  @Output()
  blur: EventEmitter<any> = new EventEmitter();
  @Output()
  focus: EventEmitter<any> = new EventEmitter();
  @Output()
  ready: EventEmitter<any> = new EventEmitter();
  @Output()
  change: EventEmitter<string> = new EventEmitter();

  onModelChange: () => void = () => { };
  onModelTouched: () => void = () => { };

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    this.editorElem = this.elementRef.nativeElement.children[0];

    const modules: any = {};

    if (this.toolbar && this.toolbar.length) {
      modules.toolbar = this.toolbar;
    }

    this.quillEditor = new Quill(this.editorElem, Object.assign({
      modules,
      placeholder: this.placeholder || '',
      readOnly: this.readonly,
      theme: 'snow',
      boundary: document.body
    }, this.options || {}));

    if (this.content) {
      this.quillEditor.pasteHTML(this.content || '');
    }

    this.ready.emit(this.quillEditor);

    // mark model as touched if editor lost focus
    this.quillEditor.on('selection-change', (range: any) => {
      if (!range) {
        this.onModelTouched();
        this.blur.emit(this.editorElem.children[0].innerHTML);
      } else {
        this.focus.emit(this.editorElem.children[0].innerHTML);
      }
    });

    // update model if text changes
    this.quillEditor.on('text-change', (delta: any, oldDelta: any, source: any) => {
      let html = this.editorElem.children[0].innerHTML;
      const text = this.quillEditor.getText();

      if (html === '<p><br></p>') { html = null; }

      // this.onModelChange(html);

      this.change.emit(html);
    });
  }

  ngOnChanges(changes: SimpleChanges) {

    setTimeout(() => {

      if (!this.quillEditor) {
        return;
      }
      if (changes['readOnly']) {
        this.quillEditor.enable(!changes['readOnly'].currentValue);
      }

      this.quillEditor.pasteHTML(this.content || '');
    });
  }

  writeValue(currentValue: any) {
    this.content = currentValue;

    if (this.quillEditor) {
      if (currentValue) {
        this.quillEditor.pasteHTML(currentValue);
        return;
      }
      this.quillEditor.setText('');
    }
  }

  registerOnChange(fn: () => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }
}
