import { ModelBase } from '../../core/models/model-base.model';
import { Employee } from './employee.model';
import { Shift } from './shift.model';
import { TimeLog } from './time-log.model';

export class Attendance extends ModelBase {
    id: string;
    employee: Employee;
    ofDate: Date;
    status: string;
    checkIn: Date;
    checkOut: Date;
    checkOutExtend: string;
    hoursWorked: number;
    minsWorked: number;
    needsAction: string;
    checkInStatus: string;
    checkOutStatus: string;
    firstHalfStatus: string;
    secondHalfStatus: string;
    count: number;
    shift: Shift;
    late: number;
    timeLogs: TimeLog[];
    early: number;
    overTime: number;
    passes: Array<{ out: TimeLog, in: TimeLog }>;
    isContinue: boolean;
    constructor(obj?: any) {
        super();
        if (!obj) {
            return null;
        }
        this.id = obj.id;
        this.code = obj.code;
        this.ofDate = obj.ofDate;
        this.status = obj.status;
        this.checkIn = obj.checkIn;
        this.checkOut = obj.checkOut;
        this.checkInStatus = obj.checkInStatus;
        this.checkOutStatus = obj.checkOutStatus;
        this.isContinue = obj.isContinue;
    }
}

export class DailyAttendance {
    id = '';
    name = '';
    code = '';
    designation = '';
    picData = '';
    picUrl = '';
    email = '';
    // leaveBalances: number = null;
    // shiftType: ShiftType = new ShiftType();
    attendance: Attendance = new Attendance({});
}

export enum AttendanceStates {
    absent,
    checkedIn,
    'checked-in-again',
    present,
    onLeave,
    halfday,
    weekOff,
    holiday
}

export class ExtendShift {
    checkOutExtend: string;
}

export class MonthAttendance {
    employee: Employee;
    absentDays: number = null;
    presentDays: number = null;
    leaveDays: number = null;
    offDays: number = null;
    holidayDays: number = null;
}
