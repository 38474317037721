import { ModelBase } from '../../core/models/model-base.model';
import { State } from './state.model';

export class Workflow extends ModelBase {

  meta: any = {};
  selectedState: State;
  states: State[] = [];
  children: Workflow[] = [];

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }

    this.meta = obj.meta || {};

    this.states = obj.states ? obj.states.map((i) => {
      if (i.next && i.next.length) {
        const next = [];

        i.next.forEach((n) => {
          next.push(obj.states.find((s) => s.code === n));
        });

        i.next = next;
      }
      return new State(i);
    }) : [];

    this.children = obj.children ? obj.children.map((w) => new Workflow(w)) : [];
  }

}
