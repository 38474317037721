import { Component, OnInit } from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { ValidationService } from 'src/app/core/services/validation.service';
import { PasswordResetBaseComponent } from 'src/app/open-age/auth/components/password-reset-base.component';
import { RoleService } from 'src/app/open-age/core/services';
import { Organization } from '~/app/open-age/directory/models';

@Component({
  selector: 'auth-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css'],
})
export class PasswordResetComponent extends PasswordResetBaseComponent {

  currentOrganization: Organization;
  constructor(
    auth: RoleService,
    validationService: ValidationService,
    uxService: UxService
  ) {
    super(auth, validationService, uxService);

    this.currentOrganization = auth.currentOrganization();
    auth.organizationChanges.subscribe((i) => this.currentOrganization = i);
  }

  focusToNext(e: HTMLInputElement, nextEle?: HTMLInputElement) {
    if (e.value && nextEle && e.maxLength === e.value.length) {
      nextEle.select();
      nextEle.focus();
    }
  }
}
