import { Component, Input, OnInit } from '@angular/core';
import { UxService } from 'src/app/core/services/ux.service';
import { ConversationDetailBaseComponent } from 'src/app/open-age/send-it/components/conversation-detail.base.component';
import { MessageService } from 'src/app/open-age/send-it/services';
import { ConversationService } from '../../../open-age/send-it/services/conversation.service';
declare var $: any;
@Component({
  selector: 'send-it-conversation-detail',
  templateUrl: './conversation-detail.component.html',
  styleUrls: ['./conversation-detail.component.css']
})
export class ConversationDetailComponent extends ConversationDetailBaseComponent {

  @Input()
  view: string;

  constructor(
    messageService: MessageService,
    conversationService: ConversationService,
    uxService: UxService
  ) {
    super(conversationService, messageService, uxService);
  }

  injectBody(elementId, body) {
    const el = document.getElementById(elementId);
    el.innerHTML = body;
  }

}
