import { ModelBase } from '../../core/models/model-base.model';

export class State extends ModelBase {

  estimate: number;
  isFirst: boolean;
  isPaused: boolean;
  isCancelled: boolean;
  isFinal: boolean;
  next: State[] = [];
  action: string;

  constructor(obj?: any) {
    super(obj);
    if (!obj) { return; }
    this.action = obj.action;
    this.estimate = obj.estimate;
    this.isFirst = obj.isFirst;
    this.isPaused = obj.isPaused;
    this.isCancelled = obj.isCancelled;
    this.isFinal = obj.isFinal;
    this.next = obj.next ? obj.next.map((i) => new State(i)) : [];
  }

}
