import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Template } from 'src/app/open-age/send-it/models';
import { TemplateService } from 'src/app/open-age/send-it/services';
import { Level } from '../../../open-age/send-it/enums/level.enum';

@Component({
  selector: 'send-it-template-picker',
  templateUrl: './template-picker.component.html',
  styleUrls: ['./template-picker.component.css']
})
export class TemplatePickerComponent implements OnInit {

  @Input()
  template: Template;

  @Input()
  level: string;

  @Input()
  placeholder = 'Select Template';

  @Output()
  change: EventEmitter<Template> = new EventEmitter();

  @ViewChild('userInput')
  userInput: ElementRef<HTMLInputElement>;

  @ViewChild('templatePicker')
  matAutocomplete: MatAutocomplete;

  control = new FormControl();

  isEditing = false;

  items: Template[];

  constructor(
    private templateService: TemplateService
  ) {

    this.control.valueChanges.pipe(
      debounceTime(300),
      switchMap((value) => this.templateService.search({ name: value, level: this.level }))
    ).subscribe((page) => {
      this.items = page.items;
    });
  }

  ngOnInit() {
    this.userInput.nativeElement.value = this.displayTemplate(this.template);
  }

  displayTemplate(template?: Template): string | undefined {
    return template ? `${template.name}` : '';
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.template = event.option.value;

    this.change.emit(this.template);

    this.userInput.nativeElement.value = this.displayTemplate(this.template);
    this.control.setValue(null);
  }
}
