import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UxService } from 'src/app/core/services/ux.service';
import { ConfigParam, Provider } from 'src/app/open-age/send-it/models';
import { ProviderService, UserService } from 'src/app/open-age/send-it/services';
import { Action, DetailBase, DetailOptions, Menu } from '~/app/open-age/core/structures';

@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.css']
})
export class ProviderDetailComponent extends DetailBase<Provider> implements OnInit {

  editorForm: FormGroup;
  constructor(
    private uxService: UxService,
    api: ProviderService,
    userService: UserService,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder
  ) {
    super({
      api
    });

    this.editorForm = formBuilder.group({
      description: ['', Validators.compose([Validators.required])],
    });
  }

  goBack(): void {
    this.location.back();
  }

  ngOnInit() {
    this.get(this.route.snapshot.params['id']);
  }

  addParameter = () => {
    this.properties.parameters.push(new ConfigParam());
  }

  removeParameter = (parameter: ConfigParam) => {
    const index = this.properties.parameters.findIndex((value) => parameter.name === value.name);
    if (index !== -1) {
      this.properties.parameters.splice(index, 1);
    }
  }
}
