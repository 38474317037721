import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ReleaseState } from '../../../open-age/gateway/models/release-state.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'gateway-workflow-state-detail',
  templateUrl: './workflow-state-detail.component.html',
  styleUrls: ['./workflow-state-detail.component.css']
})
export class WorkflowStateDetailComponent implements OnInit, OnChanges {

  @Input()
  workflowState: ReleaseState;

  @Output()
  started: EventEmitter<Date> = new EventEmitter();

  @Output()
  finished: EventEmitter<Date> = new EventEmitter();

  @Output()
  selected: EventEmitter<ReleaseState> = new EventEmitter();

  status: string;

  took: string;
  completedAt: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.calculate();
  }

  calculate() {
    if (!this.workflowState.actual.start) {
      this.status = 'planned';
    } else if (!this.workflowState.actual.finish) {
      this.status = 'wip';
    } else {
      this.status = 'done';
    }

    if (this.status === 'done') {
      this.took = moment(this.workflowState.actual.finish).from(this.workflowState.actual.start, true);
      this.completedAt = moment(this.workflowState.actual.finish).fromNow();
    }
  }

  start($event: Date) {
    this.started.emit($event);
  }
  finish($event: Date) {
    this.finished.emit($event);
  }

  select() {
    this.workflowState.isSelected = true;
    this.selected.emit(this.workflowState);
  }

}
