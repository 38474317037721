import { ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { RoleService } from 'src/app/open-age/core/services';
import { Role, User } from 'src/app/open-age/directory/models';
import { IInputValidator } from '../../core/services/input-validator.interface';

export class PasswordResetBaseComponent implements OnInit, OnChanges {

  @Output()
  success: EventEmitter<Role> = new EventEmitter();

  @Input()
  user: User;

  otp = {
    char_1: '',
    char_2: '',
    char_3: '',
    char_4: '',
    char_5: '',
    char_6: '',
  };

  email: string;
  mobile: string;
  code: string;
  password: string;

  orgCodeError: string;

  mobileError: string;
  emailError: string;
  codeError: string;

  error: string;

  isProcessing = false;
  role: Role;

  orgExists: boolean;
  createNewOrg = false;

  constructor(
    private auth: RoleService,
    private validator: IInputValidator,
    private errorHandler: ErrorHandler
  ) {
  }
  ngOnChanges(): void {
    if (this.user) {
      this.code = this.user.code;
      this.mobile = this.user.phone;
      this.email = this.user.email;
    }
  }

  ngOnInit() {
  }

  validateEmail() {
    this.emailError = this.validator.isEmailValid(this.email);
    if (this.emailError) {
      return;
    }
    this.auth.exists(this.email, 'email').subscribe((exist) => {
      if (!exist) {
        this.emailError = `email does not exist`;
      } else {
        this.emailError = '';
      }
    });
  }

  validateMobile() {
    this.mobileError = this.validator.isMobileValid(this.mobile);
    if (this.mobileError) {
      return;
    }
    this.auth.exists(this.mobile, 'mobile').subscribe((exist) => {
      if (!exist) {
        this.mobileError = `${this.mobile} does not exist`;
      } else {
        this.mobileError = '';
      }
    });
  }

  validateCode() {
    this.codeError = undefined;
    this.auth.exists(this.code, 'code').subscribe((exist) => {
      if (!exist) {
        this.codeError = `${this.code} does not exist`;
      } else {
        this.codeError = '';
      }
    });
  }

  create() {
    this.isProcessing = true;
    this.auth.sendOtp(this.email, this.mobile, this.code).subscribe((user) => {
      this.user = user;
      this.isProcessing = false;
    }, (error) => {
      this.isProcessing = false;
      this.error = error.message;
      this.errorHandler.handleError(error.message);
    });
  }

  confirm() {
    const otp = `${this.otp.char_1}${this.otp.char_2}${this.otp.char_3}${this.otp.char_4}${this.otp.char_5}${this.otp.char_6}`;
    this.isProcessing = true;
    this.auth.initPassword(this.user.id, otp, this.password).subscribe((role) => {
      this.isProcessing = false;
      this.role = role;
      this.success.next(role);
    }, (error) => {
      this.isProcessing = false;
      this.error = error.message;
      this.errorHandler.handleError(error.message);
    });
  }

  cancel() {
    this.user = null;
    this.role = null;
  }
}
