import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IPager } from '~/app/open-age/core/structures';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
})
export class PaginatorComponent implements OnInit, OnChanges {

  @Input()
  title: string;

  @Input()
  total: number;

  @Input()
  pager: IPager;

  @Input()
  showFirstLast = false;

  @Input()
  maxPagesToShow = 10;

  @Output()
  change: EventEmitter<number> = new EventEmitter();

  pages: number[];

  constructor() { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {

    if (this.pager.currentPageNo < 1) {
      this.pager.currentPageNo = 1;
    } else if (this.pager.currentPageNo > this.pager.totalPages) {
      this.pager.currentPageNo = this.pager.totalPages;
    }
    this.calculatePages();
  }

  calculatePages() {
    let index: number;

    const pageNos: number[] = [];

    let firstPage = 1;

    let lastPage = this.pager.totalPages;

    if (this.pager.totalPages > this.maxPagesToShow) {
      if (this.pager.currentPageNo < this.maxPagesToShow) {
        lastPage = this.maxPagesToShow;
      } else if (this.pager.currentPageNo > (this.pager.totalPages - this.maxPagesToShow)) {
        firstPage = this.pager.totalPages - this.maxPagesToShow;
      } else {
        firstPage = this.pager.currentPageNo - this.maxPagesToShow / 2;
        if (firstPage < 1) { firstPage = 1; }
        lastPage = this.pager.currentPageNo + this.maxPagesToShow / 2;
        if (lastPage > this.pager.totalPages) { lastPage = this.pager.totalPages; }
      }
    }

    if (firstPage !== 1) {
      pageNos.push(-2);
    }

    for (index = firstPage; index <= lastPage; index++) {
      pageNos.push(index);
    }

    if (pageNos.length === 0) {
      pageNos.push(1);
    }

    if (lastPage !== this.pager.totalPages) {
      pageNos.push(-1);
    }

    // create an array of pages to ng-repeat in the pager control
    this.pages = pageNos;
  }
  showPage(no: number) {

    if (no === -2) {
      no = 1;
    } else if (no === -1) {
      no = this.pager.totalPages;
    }

    this.pager.showPage(no).subscribe(() => {
      this.calculatePages();
      this.change.next(no);
    });

  }
}
